import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ToolGroup from './ToolGroup';
import ToolButton from './ToolButton';

import { updateObject as fabricUpdateObject } from '../../utils/fabric';
import * as images from '../../constants/images';
import { actionTypes } from '../../reducer';


function TextAlignBar(props) {
  const { state } = props;

  function updateObject(property, value) {
    return function () {
      fabricUpdateObject(state, property, value);
      props.updateObject(
        property,
        value,
      );
    };
  }

  return (
    <ToolGroup>
      <ToolButton
        isActive={state.activeObject.textAlign === 'left'}
        activeImage={images.IC_TEXTALIGN_LEFT_OVER}
        inActiveImage={images.IC_TEXTALIGN_LEFT}
        onClick={updateObject('textAlign', 'left')} />
      <ToolButton
        isActive={state.activeObject.textAlign === 'center'}
        activeImage={images.IC_TEXTALIGN_CENTER_OVER}
        inActiveImage={images.IC_TEXTALIGN_CENTER}
        onClick={updateObject('textAlign', 'center')} />
      <ToolButton
        isActive={state.activeObject.textAlign === 'right'}
        activeImage={images.IC_TEXTALIGN_RIGHT_OVER}
        inActiveImage={images.IC_TEXTALIGN_RIGHT}
        onClick={updateObject('textAlign', 'right')} />
    </ToolGroup>
  );
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateObject: (property, value) => ({
    type: actionTypes.UPDATE_OBJECT,
    property,
    value
  })
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextAlignBar);
