import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ToolGroup from './ToolGroup';
import ToolButton from './ToolButton';

import { duplicateObject, deleteObject } from '../../utils/fabric';
import * as images from '../../constants/images';
import { actionTypes } from '../../reducer';
import { calc } from '../../utils/export';


function ManageBar(props) {
  const { state } = props;

  async function duplicate() {
    try {
      await duplicateObject();
      props.selectObject();
    } catch (error) {
      console.log(error);
    }
  }

  function remove() {
    deleteObject();
    calc();
  }

  return (
    <ToolGroup>
      <ToolButton
        activeImage={images.IC_DUPLICATE}
        inActiveImage={images.IC_DUPLICATE}
        onClick={duplicate} />
      <ToolButton
        activeImage={images.IC_DELETE}
        inActiveImage={images.IC_DELETE}
        onClick={remove} />
    </ToolGroup>
  );
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  selectObject: () => ({
    type: actionTypes.SELECT_OBJECT
  }),
  deSelectObject: () => ({
    type: actionTypes.DESELECT_OBJECT
  }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageBar);
