import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CONFIRMICON from '../../assets/process_icon/confirm.svg';
import { actionTypes } from '../../reducer';

const styles = theme => ({
  root: {
    padding: '20px',
    fontSize: 30,
  },
  subject: {
    fontSize: 25,
    fontWeight: 900,
    textTransform: 'uppercase',
    marginTop: 5,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  subjectIcon: {
    marginRight: 20,
    width: 40,
    height: 42,
  },
  itemRow: {
    width: '100%',
    marginTop: 15,
    fontSize: 14,
    padding: 10,
  },
});

function ThankYou(props) {
  const { classes, state } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.subject}><img src={CONFIRMICON} className={classes.subjectIcon} alt='Review' /> THANK YOU!</Typography>
      <div className={classes.itemRow}>
        You are part of an early test group for our new neon platform that will change the game for custom neon production. 
      </div>
      <div className={classes.itemRow}>
        Your design has been submitted for review. You will receive an email confirmation with an update on timing and costs within a few weeks. 
      </div>
      <div className={classes.itemRow}>
        While we have done our best to accurately calculate costs, please note this is a ballpark figure and excludes any shipping or taxes. The final cost will be provided in an invoice within the next few weeks. <strong>Your credit card will not be charged until we have your approval .</strong>
      </div>
      <div className={classes.itemRow}>
        As this is a beta release please be advised there are many kinks that need to be worked out.  We appreciate your patience and any feedback you may have during this time. 
      </div>
      <div className={classes.itemRow}>
        If you have any questions, please email custom@ampedandco.com.
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: (property, value) => ({
    type: actionTypes.UPDATE_FORM,
    property,
    value,
  }),
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ThankYou));
