import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    height: 30,
    lineHeight: 30,
    margin: '0 10px',
    padding: 0,
    color: '#8492A7',
    listStyle: 'none',
  }
}

function ToolGroup(props) {
  const { classes, children } = props;

  return (
    <div className={classes.wrapper}>
      {children}
    </div>
  )
}

ToolGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles)(ToolGroup);
