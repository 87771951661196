import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColorPicker from './ColorPicker';
import { actionTypes } from '../../reducer';

function GroupColorPicker(props) {
  const { state } = props;
  const [color, setColor] = useState({
    index: 0,
    fill: state.activeObject.fill,
  });

  function handleChange(uid, color) {
    state.activeObject._objects.forEach(object => {
      if (object.get('type') === 'i-text') {
        object.set('fill', color);
      }
    })
    state.editor.renderAll();
    setColor({ index: 0, fill: color })
    props.updateObject('fill', color);
  }

  return (
    <ColorPicker color={color} onColorChange={handleChange} />
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateObject: (property, value) => ({
    type: actionTypes.UPDATE_OBJECT,
    property,
    value
  })
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupColorPicker);
