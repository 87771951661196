import { getLength } from "../utils/fabric";
import { FULLSCREEN_RATE } from "../constants/calculation";

const initialState = {
  lastOrderId: null,
  completeOrderId: null,
  auth: false,
  isFullScreen: false,
  editor: null,
  width: 0,
  height: 0,
  length: 0,
  bgImage: 0,
  bgType: 0,
  powerCord: 'White',
  activeObject: null,
  innerBoundary: null,
  internalObject: {},
  magnification: 1,
  signAreaWidth: 0,
  signAreaHeight: 0,
  isBoundaryAvailable: true,
  individualPackagingW: 0,
  individualPackagingH: 0,
  masterPackagingT: 0,
  masterPackagingW: 0,
  masterPackagingH: 0,
  dimensionalWeight: 0,
  baseCost: 100,
  artWork: 0,
  mounting: 0,
  productTotalCost: 100,
  packagingCost: 0,
  driverType: "",
  colorList: [],
  form: {
    company: "",
    name: "",
    email: "",
    phone: "",
    shippingAddress1: "",
    shippingAddress2: "",
    city: "",
    state: "",
    zip: "",
    creditCard: "",
    expiry: "",
    cvc: "",
  }
};

export const actionTypes = {
  UPDATE_LAST_ORDER_ID: 'UPDATE_LAST_ORDER_ID',
  UPDATE_COMPLETE_ORDER_ID: 'UPDATE_COMPLETE_ORDER_ID',
  UPDATE_AUTH: 'UPDATE_AUTH',
  TOGGLE_FULLSCREEN: 'TOGGLE_FULLSCREEN',
  SET_EDITOR: 'SET_EDITOR',
  UPDATE_BG_IMAGE: 'UPDATE_BG_IMAGE',
  UPDATE_BG_TYPE: 'UPDATE_BG_TYPE',
  UPDATE_POWER_CODE: 'UPDATE_POWER_CODE',
  UPDATE_BOUNDARY: 'UPDATE_BOUNDARY',
  SELECT_OBJECT: 'SELECT_OBJECT',
  DESELECT_OBJECT: 'DESELECT_OBJECT',
  UPDATE_OBJECT: 'UPDATE_OBJECT',
  SET_INNER_OBJECT: 'SET_INNER_OBJECT',
  HIDE_BOUNDARY: 'HIDE_BOUNDARY',
  UPDATE_CALCULATION: 'UPDATE_CALCULATION',
  UPDATE_FORM: 'UPDATE_FORM'
}

function rootReducer(state = initialState, action) {
  console.log('diapatching....', action)
  switch (action.type) {
    case actionTypes.UPDATE_LAST_ORDER_ID:
      return {
        ...state,
        lastOrderId: action.id,
      }
    case actionTypes.UPDATE_COMPLETE_ORDER_ID:
      return {
        ...state,
        completeOrderId: action.id,
      }
    case actionTypes.UPDATE_AUTH:
      return {
        ...state,
        auth: action.value,
      };
    case actionTypes.TOGGLE_FULLSCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
        magnification: state.isFullScreen ? 1 : FULLSCREEN_RATE
      };
    case actionTypes.SET_EDITOR:
      return {
        ...state,
        editor: action.editor,
        innerBoundary: action.innerBoundary,
      };
    case actionTypes.UPDATE_BG_IMAGE:
      return {
        ...state,
        bgImage: action.value,
      }
    case actionTypes.UPDATE_BG_TYPE:
      return {
        ...state,
        bgType: action.bgType,
      };
    case actionTypes.UPDATE_POWER_CODE:
      return {
        ...state,
        powerCord: action.powerCordType,
      };
    case actionTypes.SELECT_OBJECT:
      return {
        ...state,
        activeObject: state.editor.getActiveObject(),
        length: getLength(state.editor.getActiveObject()).origin,
      };
    case actionTypes.DESELECT_OBJECT:
      return {
        ...state,
        activeObject: null,
        internalObject: {},
        length: 0,
      };
    case actionTypes.UPDATE_OBJECT:
      return {
        ...state,
        internalObject: { ...state.internalObject, [action.property]: action.value },
        length: getLength(state.editor.getActiveObject()).origin,
      };
    case actionTypes.SET_INNER_OBJECT:
      return {
        ...state,
        internalObject: { ...state.internalObject, ...state.activeObject.toJSON() },
      };
    case actionTypes.UPDATE_CALCULATION:
      return {
        ...state,
        ...action.calc,
      }
    case actionTypes.HIDE_BOUNDARY:
      return {
        ...state,
        isBoundaryAvailable: false,
      }
    case actionTypes.UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.property]: action.value,
        },
      };
    default:
      return state;
  }
}

export default rootReducer;
