import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

import { openedDrawerWidth } from '../theme';
import { round, debounce } from '../../utils/helper';
import { bgIconsList } from '../../constants/calculation';
import { firebaseDB } from '../../containers/App';
import { exportFactoryPDF, exportCustomerPDF } from '../../utils/export';
import { actionTypes } from '../../reducer';

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: '1px',
    width: openedDrawerWidth,
    height: 350,
    border: '1px solid #707070',
    boxShadow: '0px -6px 10px -7px #707070',
    backgroundColor: 'white',
  },
  container: {
    position: 'fixed',
    bottom: '1px',
    width: openedDrawerWidth,
    border: '1px solid #707070',
    boxShadow: '0px -6px 10px -7px #707070',
    backgroundColor: 'white',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepRoot: {
    padding: 15,
  },
  step: {
    width: 30,
    height: 30,
    '& $completed': {
      color: '#3b86ff',
    },
  },
  alternativeLabel: {},
  completed: {},
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 6,
      fontSize: 12,
    }
  },
  priceInfo: {
    marginTop: 10,
    padding: 15,
  },
  priceRow: {
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 27,
    textAlign: 'right',
  },
  priceTitle: {
    width: '50%',
    float: 'left',
  },
  priceVal: {
    width: '50%',
    fontWeight: 900,
  },
  buttons: {
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    width: '40%',
    textTransform: 'uppercase',
  },
  smallButton: {
    width: '35%',
    textTransform: 'uppercase',
  },
  prevButton: {
    float: 'left',
    marginLeft: 20,
  },
  nextButton: {
    backgroundColor: '#04d143',
    color: '#ffffff',
    float: 'right',
    marginRight: 20,
    '&:hover': {
      backgroundColor: '#04d143',
      opacity: 0.9,
    },
  },
  downloadButton: {
    backgroundColor: '#04d143',
    color: '#ffffff',
    marginBottom: 10,
    width: '55%',
    '&:hover': {
      backgroundColor: '#04d143',
      opacity: 0.9,
    },
  },
  startButton: {
    width: '55%',
  },
});

function getSteps() {
  return ['DESIGN', 'REVIEW', 'CONFIRM'];
}

function onSaveFirebaseStore(state, props) {
  const { lastOrderId: id } = state;
  const currentDate = new Date();
  const datetime = currentDate.getFullYear() + "-" +
    (currentDate.getMonth() + 1) + "-" +
    currentDate.getDate() + " " +
    currentDate.getHours() + ":" +
    currentDate.getMinutes() + ":" +
    currentDate.getSeconds();

  let driverType = "";
  if (state.driverType === '3kv') {
    driverType = '"3kV" (1 - 2m)';
  } else if (state.driverType === '4kv') {
    driverType = '"4kV" (2 - 3m)';
  } else if (state.driverType === '6kv') {
    driverType = '"6kV" (3 - 4.5m)';
  } else if (state.driverType === '8kv') {
    driverType = '"8kV" (4.5 - 6.5m)';
  } else if (state.driverType === '10kv') {
    driverType = '"10kV" (6.5 - 8.5m)';
  }

  let orderNumber = ''
  if (id < 10) orderNumber = '000' + id
  else if (id >= 10 && id < 100) orderNumber = '00' + id
  else if (id >= 100 && id < 1000) orderNumber = '0' + id
  else orderNumber = '' + id

  // Store order data to Firebase
  firebaseDB.collection('Order').doc(orderNumber).set({
    'Order Date': datetime,
    'Order Number': 'CUSTOM-' + orderNumber,
    'Sign Width (cm)': state.signAreaWidth,
    'Sign Height (cm)': state.signAreaHeight,
    'Tube Colour': state.colorList.join(', '),
    'Mounting Style': bgIconsList[state.bgType].title,
    'Power Cord': state.powerCord,
    'Path Length': state.lettersPathLength,
    'Total Length': state.lettersPathLengthWithVariance,
    'Driver': driverType,
    'Inner Box Length (cm)': 12,
    'Inner Box Width (cm)': state.individualPackagingW,
    'Inner Box Height (cm)': state.individualPackagingH,
    'Shipping Box Length (cm)': state.masterPackagingT,
    'Shipping Box Width (cm)': state.masterPackagingW,
    'Shipping Box Height (cm)': state.masterPackagingH,
    'Dimensional Weight (kg)': state.dimensionalWeight,
    'Factory Cost': state.productTotalCost,
    'Customer Cost:': state.productTotalCost * 2 + 100,
    'Company Name': state.form.company,
    'Contact Name': state.form.name,
    'Shipping Address 1': state.form.shippingAddress1,
    'Shipping Address 2': state.form.shippingAddress2,
    'City': state.form.city,
    'State': state.form.state,
    'Zip Code': state.form.zip,
    'Email': state.form.email,
    'Phone': state.form.phone,
    'Credit Card': state.form.creditCard,
    'Expiry': state.form.expiry,
    'CVC': state.form.cvc,
  }, { merge: false })

  // Store last id to Firebase
  firebaseDB.collection('Status').doc('Order').set({
    lastId: parseInt(id) + 1,
  }, { merge: false })

  props.setCompleteOrderId(parseInt(id));
}

function ResultBoard(props) {
  const { classes, updateStep, state } = props;
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === 3) {
      // Complete order
      onSaveFirebaseStore(state, props);
    }

    updateStep((activeStep + 1) > 4 ? 4 : activeStep + 1);
    setActiveStep((activeStep + 1) > 4 ? 4 : activeStep + 1);
  };

  const handleBack = () => {
    updateStep((activeStep - 1) < 0 ? 0 : activeStep - 1);
    setActiveStep((activeStep - 1) < 0 ? 0 : activeStep - 1);
  };

  const handleDownload = () => {
    exportFactoryPDF();
    exportCustomerPDF();
  }

  const handleStartOver = () => {
    updateStep(0);
    setActiveStep(0);
  }

  return (
    <div>
      {activeStep < 4 ? (
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            classes={{
              root: classes.stepRoot
            }}
          >
            {steps.map(label => {
              return (
                <Step
                  key={label}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                  }}
                >
                  <StepLabel
                    classes={{
                      alternativeLabel: classes.alternativeLabel,
                      labelContainer: classes.labelContainer
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.priceInfo}>
            <Typography className={classes.priceRow}>
              <label className={classes.priceTitle}>Base price:</label> <label className={classes.priceVal}>{`$100`}</label>
            </Typography>
            <Typography className={classes.priceRow}>
              <label className={classes.priceTitle}>Artwork:</label> <label className={classes.priceVal}>{`$${round(state.artWork * 2, 2)}`}</label>
            </Typography>
            <Typography className={classes.priceRow}>
              <label className={classes.priceTitle}>Mounting:</label> <label className={classes.priceVal}>{`$${round(state.mounting * 2, 2)}`}</label>
            </Typography>
            <Divider />
            <Typography className={classes.priceRow}>
              <label className={classes.priceTitle}>Total price:</label> <label className={classes.priceVal}>{`$${round(state.productTotalCost * 2 + 100, 2)}`}</label>
            </Typography>
          </div>
          <div className={classes.buttons}>
            {activeStep !== 0 && activeStep < 3 &&
              <Button
                variant='outlined'
                className={classNames(classes.button, classes.prevButton)}
                onClick={handleBack}
              >

                BACK
              </Button>
            }
            {activeStep < 3 &&
              <Button
                variant='outlined'
                className={classNames(classes.button, classes.nextButton)}
                onClick={handleNext}
                disabled={
                  activeStep === 2 &&
                  (
                    state.form.company === '' ||
                    state.form.name === '' ||
                    state.form.email === '' ||
                    state.form.phone === '' ||
                    state.form.shippingAddress1 === '' ||
                    state.form.city === '' ||
                    state.form.state === '' ||
                    state.form.zip === '' ||
                    state.form.creditCard === '' ||
                    state.form.expiry === '' ||
                    state.form.cvc === ''
                  )
                }
              >
                Next
          </Button>
            }
            {activeStep === 3 &&
              <React.Fragment>
                <Button
                  variant='outlined'
                  className={classNames(classes.smallButton, classes.prevButton)}
                  onClick={handleBack}
                >
                  BACK
                </Button>
                <Button
                  variant='outlined'
                  className={classes.nextButton}
                  onClick={handleNext}
                >
                  Complete order
                </Button>
              </React.Fragment>
            }
          </div>
        </div>
      ) : (
          <div className={classes.container}>
            <Button
              variant='outlined'
              className={classes.downloadButton}
              onClick={handleDownload}
            >
              Download PDF
            </Button>
            <Button
              variant='outlined'
              className={classes.startButton}
              onClick={handleStartOver}
            >
              Start Over
            </Button>
          </div>
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  setCompleteOrderId: (id) => ({
    type: actionTypes.UPDATE_COMPLETE_ORDER_ID,
    id,
  }),
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResultBoard));
