import {
  SCREEN_SCALE
} from "../constants/calculation";

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export function round(number, precision) {
  let shift = function (number, precision, reverseShift) {
    if (reverseShift) {
      precision = -precision;
    }
    const numArray = ("" + number).split("e");
    return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
  };
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

export function trimCanvas(c, unit = 1, type) {
  var ctx = c.getContext('2d'),
    w = (~~c.width) * SCREEN_SCALE,
    h = (~~c.height) * SCREEN_SCALE,
    copy = document.createElement('canvas').getContext('2d'),
    pixels = ctx.getImageData(0, 0, w, h),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x, y;

  for (i = 0; i < l; i += 4 * unit) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % w;
      y = ((i / 4) - x) / w;

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }


  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left;

  if (type === 'canvas') {
    var trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);
    return copy.canvas;
  } else {
    var size = {
      'width': trimWidth,
      'height': trimHeight
    };
    return size;
  }

}

export function getRealSize(object) {
  return new Promise((resolve, reject) => {
    object.clone(function (cloned) {
      window.downloadCanvas.clear();
      window.downloadCanvas.setWidth(cloned.width + 30);
      window.downloadCanvas.setHeight(cloned.height + 30);
      window.downloadCanvas.add(cloned);
      window.downloadCanvas.setZoom(1);
      window.downloadCanvas.centerObject(cloned);
      window.downloadCanvas.discardActiveObject();
      window.downloadCanvas.renderAll();
      const realSize = trimCanvas(window.downloadCanvas);
      resolve(realSize)
    });

  })
}

export function getDataUri(url) {
  return new Promise((resolve, reject) => {
    var image = new Image();

    image.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(this, 0, 0);

      // ... or get as Data URI
      resolve(canvas.toDataURL('image/png'));
    };

    image.src = url;
  });
}