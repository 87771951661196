import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '20px',
    fontSize: 30,
  }
});

function PremadeTab(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      Coming soon
    </div>
  )
}

export default withStyles(styles)(PremadeTab)
