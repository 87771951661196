export const LETTER_PATH_SIZE = {
  Allura: {
    "a": { path: 25.3, width: 9.6, height: 6 },
    "b": { path: 43, width: 9.6, height: 13.4 },
    "c": { path: 16.4, width: 8, height: 7.2 },
    "d": { path: 43.2, width: 13.9, height: 15 },
    "e": { path: 21.1, width: 8, height: 7.3 },
    "f": { path: 29.2, width: 11.5, height: 19.1 },
    "g": { path: 28.9, width: 11.8, height: 12.8 },
    "h": { path: 45.4, width: 10.7, height: 13.1 },
    "i": { path: 16.8, width: 7, height: 9.7 },
    "j": { path: 32.3, width: 11.9, height: 16.9 },
    "k": { path: 43.9, width: 9.2, height: 15.9 },
    "l": { path: 30.2, width: 8.6, height: 13.1 },
    "m": { path: 32.9, width: 13.2, height: 6.9 },
    "n": { path: 23.4, width: 10.2, height: 6.7 },
    "o": { path: 15.2, width: 6.2, height: 7.3 },
    "p": { path: 25.8, width: 10, height: 13.7 },
    "q": { path: 43.7, width: 10.8, height: 15 },
    "r": { path: 23.3, width: 8.3, height: 8.8 },
    "s": { path: 17.9, width: 8, height: 9.2 },
    "t": { path: 18.8, width: 6.4, height: 9.7 },
    "u": { path: 24.2, width: 10.2, height: 7 },
    "v": { path: 17.9, width: 7.8, height: 8.4 },
    "w": { path: 28.1, width: 11.6, height: 8.1 },
    "x": { path: 26.5, width: 11.2, height: 10.8 },
    "y": { path: 44, width: 12.8, height: 13.9 },
    "z": { path: 28.2, width: 10.2, height: 7.3 },
    "A": { path: 56.4, width: 19, height: 14.3 },
    "B": { path: 53.9, width: 15, height: 13.9 },
    "C": { path: 37.5, width: 12.4, height: 13.5 },
    "D": { path: 57.9, width: 16.9, height: 13.7 },
    "E": { path: 39.9, width: 14.2, height: 14 },
    "F": { path: 40, width: 15, height: 12.6 },
    "G": { path: 66.2, width: 15.1, height: 19.9 },
    "H": { path: 57.4, width: 19.3, height: 15 },
    "I": { path: 32.5, width: 12.3, height: 12.3 },
    "J": { path: 40.8, width: 12.1, height: 14.2 },
    "K": { path: 45.3, width: 19, height: 15.1 },
    "L": { path: 48.8, width: 15.3, height: 16.7 },
    "M": { path: 74.6, width: 23.9, height: 14.2 },
    "N": { path: 51.5, width: 20.9, height: 15.1 },
    "O": { path: 47.8, width: 13.9, height: 14.2 },
    "P": { path: 42.6, width: 14.2, height: 12.6 },
    "Q": { path: 69.8, width: 18.3, height: 16.9 },
    "R": { path: 58.3, width: 17.1, height: 15.6 },
    "S": { path: 38.6, width: 13.1, height: 128 },
    "T": { path: 30.8, width: 14.7, height: 12.6 },
    "U": { path: 57.7, width: 14, height: 16.4 },
    "V": { path: 43.4, width: 18.3, height: 14 },
    "W": { path: 67.7, width: 19.9, height: 13.5 },
    "X": { path: 51.5, width: 19.9, height: 15.3 },
    "Y": { path: 70.9, width: 14.7, height: 19.1 },
    "Z": { path: 53.9, width: 15.1, height: 16.1 },
    "1": { path: 21, width: 7.8, height: 11.6 },
    "2": { path: 32.8, width: 11, height: 11.6 },
    "3": { path: 30.8, width: 9.7, height: 12 },
    "4": { path: 32.8, width: 11.3, height: 12.1 },
    "5": { path: 31.9, width: 12.4, height: 12 },
    "6": { path: 29.8, width: 9.4, height: 11.8 },
    "7": { path: 21.8, width: 10.2, height: 11.5 },
    "8": { path: 35.4, width: 10, height: 11.8 },
    "9": { path: 29.6, width: 9.4, height: 11.8 },
    "0": { path: 32.4, width: 10.5, height: 12.3 },
    "!": { path: 10.2, width: 6.1, height: 12.8 },
    "@": { path: 67.9, width: 14.8, height: 13.5 },
    "#": { path: 51, width: 15.8, height: 13.9 },
    "$": { path: 33.2, width: 8.6, height: 12 },
    "%": { path: 35.2, width: 8.1, height: 12 },
    "&": { path: 44, width: 12.9, height: 13.1 },
    "*": { path: 23, width: 9.6, height: 8.1 },
    "(": { path: 17.7, width: 9.2, height: 15.8 },
    ")": { path: 17.7, width: 9.2, height: 15.8 },
    ",": { path: 4.1, width: 3.5, height: 4.8 },
    ".": { path: 1.9, width: 2.7, height: 3 },
    "/": { path: 13.2, width: 9.1, height: 12.4 },
    "<": { path: 11.2, width: 6.5, height: 10 },
    ">": { path: 11.2, width: 6.5, height: 10 },
    "?": { path: 16.9, width: 8, height: 12.6 },
    ";": { path: 4.9, width: 4.8, height: 8.1 },
    ":": { path: 2.7, width: 3.3, height: 5.7 },
    "\"": { path: 7.2, width: 5.3, height: 4.5 },
    "{": { path: 19.3, width: 8.1, height: 13.1 },
    "}": { path: 19.3, width: 8.1, height: 13.1 },
    "[": { path: 16.9, width: 10, height: 12 },
    "]": { path: 16.9, width: 10, height: 12 },
    "|": { path: 12.3, width: 1.6, height: 13.9 },
    "\\": { path: 11.8, width: 6.1, height: 12.4 },
    "=": { path: 14.3, width: 9.4, height: 4.5 },
    "+": { path: 16.9, width: 10.2, height: 9.1 },
  },
  Sanchez: {
    "a": { path: 21.1, width: 6.1, height: 6.2 },
    "b": { path: 24.3, width: 8.4, height: 10.7 },
    "c": { path: 17.4, width: 6.9, height: 7.6 },
    "d": { path: 25.8, width: 8.4, height: 10.7 },
    "e": { path: 21.5, width: 6.8, height: 7.6 },
    "f": { path: 16.1, width: 4.8, height: 10.7 },
    "g": { path: 28.4, width: 8.3, height: 10.8 },
    "h": { path: 23.9, width: 9.2, height: 10.6 },
    "i": { path: 10.5, width: 3.9, height: 10.2 },
    "j": { path: 16.1, width: 3.9, height: 13.7 },
    "k": { path: 21.8, width: 9.2, height: 10.6 },
    "l": { path: 12.2, width: 3.9, height: 10.6 },
    "m": { path: 29.6, width: 13.6, height: 7.5 },
    "n": { path: 19.6, width: 9.2, height: 7.5 },
    "o": { path: 19.7, width: 7.5, height: 7.6 },
    "p": { path: 15, width: 8, height: 10.7 },
    "q": { path: 23.4, width: 8, height: 10.7 },
    "r": { path: 11.3, width: 6, height: 7.4 },
    "s": { path: 21, width: 6, height: 7.6 },
    "t": { path: 13.2, width: 5, height: 9 },
    "u": { path: 20, width: 9.2, height: 7.4 },
    "v": { path: 15, width: 8.3, height: 7.2 },
    "w": { path: 26.6, width: 11.7, height: 7.2 },
    "x": { path: 17.9, width: 7.7, height: 7.4 },
    "y": { path: 19, width: 8.2, height: 10.7 },
    "z": { path: 22.4, width: 7.1, height: 7.4 },
    "A": { path: 26, width: 11, height: 10.6 },
    "B": { path: 31.7, width: 8.7, height: 10.6 },
    "C": { path: 26.8, width: 9.4, height: 10.8 },
    "D": { path: 29.3, width: 9.4, height: 10.6 },
    "E": { path: 27.3, width: 8.2, height: 10.6 },
    "F": { path: 21.3, width: 8, height: 10.6 },
    "G": { path: 32.1, width: 9.5, height: 10.8 },
    "H": { path: 29.4, width: 10.7, height: 10.6 },
    "I": { path: 12.8, width: 4, height: 10.6 },
    "J": { path: 13.2, width: 6, height: 10.7 },
    "K": { path: 26.8, width: 9.9, height: 10.6 },
    "L": { path: 18.8, width: 8.4, height: 10.6 },
    "M": { path: 39.3, width: 12, height: 10.6 },
    "N": { path: 34, width: 10.6, height: 10.6 },
    "O": { path: 28.8, width: 10, height: 10.8 },
    "P": { path: 24.8, width: 8.2, height: 10.6 },
    "Q": { path: 32.7, width: 10, height: 12.3 },
    "R": { path: 28.8, width: 9.2, height: 10.6 },
    "S": { path: 31.8, width: 8.1, height: 10.8 },
    "T": { path: 21.3, width: 9.2, height: 10.6 },
    "U": { path: 26.3, width: 10.6, height: 10.7 },
    "V": { path: 23.3, width: 11.1, height: 10.6 },
    "W": { path: 40.4, width: 14.9, height: 10.6 },
    "X": { path: 29.2, width: 10.5, height: 10.6 },
    "Y": { path: 19.6, width: 9.9, height: 10.6 },
    "Z": { path: 28.2, width: 8.1, height: 10.6 },
    "1": { path: 16.2, width: 6.3, height: 10.6 },
    "2": { path: 26.3, width: 7.6, height: 10.7 },
    "3": { path: 24.3, width: 7.2, height: 10.8 },
    "4": { path: 27.4, width: 8.6, height: 10.6 },
    "5": { path: 26.2, width: 7.2, height: 10.7 },
    "6": { path: 28.6, width: 7.7, height: 10.7 },
    "7": { path: 17.8, width: 7.5, height: 10.6 },
    "8": { path: 30, width: 7.8, height: 10.8 },
    "9": { path: 28.6, width: 7.7, height: 10.7 },
    "0": { path: 26.2, width: 8.3, height: 10.8 },
    "!": { path: 9, width: 1.2, height: 11.9 },
    "@": { path: 43, width: 10.5, height: 11.2 },
    "#": { path: 29, width: 9, height: 8.3 },
    "$": { path: 33, width: 8.1, height: 14.2 },
    "%": { path: 36.6, width: 10.2, height: 12.4 },
    "&": { path: 35.6, width: 8.8, height: 10.8 },
    "*": { path: 29.6, width: 6, height: 6 },
    "(": { path: 15.2, width: 5.2, height: 12.3 },
    ")": { path: 15.2, width: 5.2, height: 12.3 },
    ",": { path: 2.6, width: 2.1, height: 3 },
    ".": { path: 1.4, width: 1.2, height: 2.4 },
    "/": { path: 11.8, width: 4.6, height: 12.4 },
    "<": { path: 11.6, width: 6.5, height: 5.8 },
    ">": { path: 11.6, width: 6.5, height: 5.8 },
    "?": { path: 16.4, width: 7.2, height: 11.4 },
    ";": { path: 3.9, width: 2.1, height: 9.5 },
    ":": { path: 2.8, width: 1.2, height: 8.4 },
    "\"": { path: 5, width: 4.5, height: 3 },
    "{": { path: 15.9, width: 5.1, height: 12.3 },
    "}": { path: 15.9, width: 5.1, height: 12.3 },
    "[": { path: 15.4, width: 3.4, height: 12.3 },
    "]": { path: 15.4, width: 3.4, height: 12.3 },
    "|": { path: 11.9, width: 1.2, height: 13.2 },
    "\\": { path: 11.7, width: 4.6, height: 12.4 },
    "=": { path: 8, width: 5.2, height: 4.6 },
    "+": { path: 7.2, width: 4.8, height: 4.7 },
  },
  Nunito: {
    "a": { path: 17.5, width: 4.9, height: 6.3 },
    "b": { path: 20.1, width: 8.8, height: 5.6 },
    "c": { path: 12.9, width: 5, height: 6.3 },
    "d": { path: 20.1, width: 5.6, height: 8.8 },
    "e": { path: 18.5, width: 5.4, height: 6.3 },
    "f": { path: 13.4, width: 4.4, height: 8.8 },
    "g": { path: 23.1, width: 5.6, height: 8.5 },
    "h": { path: 16.9, width: 5.4, height: 8.8 },
    "i": { path: 7, width: 0.9, height: 8.8 },
    "j": { path: 10.4, width: 2.9, height: 11 },
    "k": { path: 16.7, width: 4.9, height: 8.8 },
    "l": { path: 8.8, width: 0.9, height: 8.8 },
    "m": { path: 22, width: 8.5, height: 6.3 },
    "n": { path: 14.3, width: 5.1, height: 6.3 },
    "o": { path: 17.1, width: 5.7, height: 6.3 },
    "p": { path: 19.6, width: 5.6, height: 8.4 },
    "q": { path: 19.6, width: 5.6, height: 8.4 },
    "r": { path: 8, width: 3.5, height: 6.3 },
    "s": { path: 14.7, width: 4.7, height: 6.2 },
    "t": { path: 12.4, width: 4.2, height: 8 },
    "u": { path: 14.3, width: 5, height: 6.3 },
    "v": { path: 12.5, width: 5.9, height: 6.2 },
    "w": { path: 23.6, width: 9.5, height: 6.2 },
    "x": { path: 14.7, width: 5.6, height: 6.1 },
    "y": { path: 13.9, width: 5.9, height: 8.3 },
    "z": { path: 16.3, width: 5.5, height: 6.1 },
    "A": { path: 21.8, width: 8.1, height: 8.7 },
    "B": { path: 28.5, width: 6.4, height: 8.7 },
    "C": { path: 18.6, width: 7.1, height: 8.9 },
    "D": { path: 25.9, width: 7.1, height: 8.7 },
    "E": { path: 21.7, width: 5.5, height: 8.7 },
    "F": { path: 17.2, width: 5.9, height: 8.7 },
    "G": { path: 24, width: 7.1, height: 9.1 },
    "H": { path: 21.1, width: 7.1, height: 8.7 },
    "I": { path: 8.7, width: 0.9, height: 8.7 },
    "J": { path: 10.1, width: 3.1, height: 8.7 },
    "K": { path: 20.5, width: 6.4, height: 8.7 },
    "L": { path: 13.1, width: 5.3, height: 8.7 },
    "M": { path: 31.9, width: 8.1, height: 8.7 },
    "N": { path: 26.4, width: 6.9, height: 8.7 },
    "O": { path: 24.6, width: 7.7, height: 9.1 },
    "P": { path: 21.2, width: 6.3, height: 8.7 },
    "Q": { path: 26.4, width: 7.7, height: 11 },
    "R": { path: 24.3, width: 6.5, height: 8.7 },
    "S": { path: 21.6, width: 6.5, height: 8.7 },
    "T": { path: 13.9, width: 7.2, height: 8.7 },
    "U": { path: 20.2, width: 6.9, height: 8.8 },
    "V": { path: 18.4, width: 8, height: 8.7 },
    "W": { path: 34.3, width: 12.5, height: 8.7 },
    "X": { path: 21, width: 7.3, height: 8.7 },
    "Y": { path: 14.1, width: 7.4, height: 8.7 },
    "Z": { path: 21.4, width: 6.5, height: 8.7 },
    "1": { path: 14.3, width: 5.1, height: 8.7 },
    "2": { path: 19, width: 5.9, height: 8.8 },
    "3": { path: 21.3, width: 5.8, height: 8.9 },
    "4": { path: 21.2, width: 6.2, height: 8.7 },
    "5": { path: 22.3, width: 5.8, height: 8.8 },
    "6": { path: 23.8, width: 6, height: 8.9 },
    "7": { path: 14.7, width: 5.9, height: 8.7 },
    "8": { path: 25.9, width: 6.2, height: 8.9 },
    "9": { path: 23.8, width: 6, height: 8.7 },
    "0": { path: 22.2, width: 6, height: 8.9 },
    "!": { path: 7.2, width: 0.9, height: 8.7 },
    "@": { path: 45, width: 10.2, height: 10.7 },
    "#": { path: 28.2, width: 6.8, height: 8.9 },
    "$": { path: 22.5, width: 6.3, height: 12.2 },
    "%": { path: 35.7, width: 10, height: 9.1 },
    "&": { path: 30.5, width: 7.4, height: 8.9 },
    "*": { path: 15, width: 4.9, height: 4.4 },
    "(": { path: 11.3, width: 20, height: 11 },
    ")": { path: 11.3, width: 20, height: 11 },
    ",": { path: 2.9, width: 1.4, height: 2.8 },
    ".": { path: 2.1, width: 0.9, height: 2.1 },
    "/": { path: 10.5, width: 3.8, height: 10 },
    "<": { path: 12.5, width: 6, height: 5.8 },
    ">": { path: 12.5, width: 6, height: 5.8 },
    "?": { path: 11.6, width: 5.1, height: 8.7 },
    ";": { path: 4.1, width: 1.4, height: 7.9 },
    ":": { path: 3.3, width: 0.9, height: 6.9 },
    "\"": { path: 5, width: 4.4, height: 3.6 },
    "{": { path: 15.3, width: 3.9, height: 11 },
    "}": { path: 15.3, width: 3.9, height: 11 },
    "[": { path: 13.6, width: 2.6, height: 11 },
    "]": { path: 13.6, width: 2.6, height: 11 },
    "|": { path: 11, width: 0.9, height: 11 },
    "\\": { path: 10.5, width: 3.8, height: 10 },
    "=": { path: 12.2, width: 6.1, height: 3.4 },
    "+": { path: 12.2, width: 6.1, height: 6.1 },
  },
  AmaticSC: {
    "a": { path: 26, width: 4.7, height: 12.7 },
    "b": { path: 26.8, width: 3.5, height: 12.6 },
    "c": { path: 14.2, width: 3.5, height: 12.6 },
    "d": { path: 25.7, width: 4.2, height: 12.1 },
    "e": { path: 17.2, width: 3, height: 12.5 },
    "f": { path: 15, width: 3.2, height: 12.5 },
    "g": { path: 20.9, width: 4.5, height: 12.6 },
    "h": { path: 25.1, width: 3.6, height: 12.5 },
    "i": { path: 16, width: 4, height: 12 },
    "j": { path: 16.5, width: 3.9, height: 14.3 },
    "k": { path: 23.8, width: 4.5, height: 12.4 },
    "l": { path: 13.2, width: 3, height: 12.2 },
    "m": { path: 44.3, width: 7.7, height: 13.2 },
    "n": { path: 34.4, width: 5.9, height: 12.5 },
    "o": { path: 25.9, width: 4.6, height: 12.6 },
    "p": { path: 18.9, width: 3.1, height: 12.6 },
    "q": { path: 30.6, width: 4.7, height: 16 },
    "r": { path: 24.7, width: 3.6, height: 12.6 },
    "s": { path: 14.4, width: 3.2, height: 12.6 },
    "t": { path: 14.5, width: 5, height: 12 },
    "u": { path: 23.7, width: 4.4, height: 12.6 },
    "v": { path: 23.3, width: 5.4, height: 12.6 },
    "w": { path: 42.8, width: 6, height: 12.5 },
    "x": { path: 23.2, width: 3.8, height: 12.5 },
    "y": { path: 17.2, width: 3.9, height: 12.5 },
    "z": { path: 15.4, width: 3.7, height: 12.1 },
    "A": { path: 28.1, width: 4.7, height: 14 },
    "B": { path: 29.8, width: 3.7, height: 13.9 },
    "C": { path: 17.8, width: 5, height: 14 },
    "D": { path: 28.6, width: 4.8, height: 13.5 },
    "E": { path: 17.4, width: 3, height: 13.9 },
    "F": { path: 15.7, width: 2.6, height: 13.9 },
    "G": { path: 22, width: 5.3, height: 14.3 },
    "H": { path: 27.8, width: 4, height: 13.9 },
    "I": { path: 17, width: 3.3, height: 13.9 },
    "J": { path: 17.2, width: 3.3, height: 15.5 },
    "K": { path: 28, width: 4.8, height: 14.2 },
    "L": { path: 14.9, width: 3.3, height: 13.9 },
    "M": { path: 49.3, width: 5.8, height: 14.3 },
    "N": { path: 39.2, width: 5.3, height: 14 },
    "O": { path: 29, width: 4.6, height: 14.2 },
    "P": { path: 21, width: 3.1, height: 14.2 },
    "Q": { path: 32.8, width: 5.1, height: 16.5 },
    "R": { path: 27.5, width: 3.9, height: 13.9 },
    "S": { path: 17, width: 3.7, height: 14.3 },
    "T": { path: 17.2, width: 5.4, height: 13.8 },
    "U": { path: 27, width: 5, height: 13.9 },
    "V": { path: 25.9, width: 5, height: 14 },
    "W": { path: 50.6, width: 6.7, height: 14.5 },
    "X": { path: 26.7, width: 3.9, height: 14.3 },
    "Y": { path: 19, width: 3.6, height: 14.2 },
    "Z": { path: 17.3, width: 3.1, height: 14.1 },
    "1": { path: 17.4, width: 4.9, height: 13.6 },
    "2": { path: 19, width: 3.9, height: 14.4 },
    "3": { path: 17.7, width: 4.3, height: 13.9 },
    "4": { path: 21.7, width: 3.8, height: 14.2 },
    "5": { path: 18, width: 4, height: 14.4 },
    "6": { path: 23.2, width: 3.4, height: 14.5 },
    "7": { path: 15.4, width: 3.1, height: 14.2 },
    "8": { path: 30.9, width: 3.4, height: 15.2 },
    "9": { path: 22.2, width: 3.8, height: 14.5 },
    "0": { path: 28.8, width: 4.4, height: 14 },
    "!": { path: 11.3, width: 1.2, height: 13.8 },
    "@": { path: 54.3, width: 7.7, height: 16.1 },
    "#": { path: 37, width: 6.4, height: 14.6 },
    "$": { path: 30.5, width: 3.8, height: 15 },
    "%": { path: 42.7, width: 8.5, height: 13.9 },
    "&": { path: 35.5, width: 7.3, height: 13.3 },
    "*": { path: 21.4, width: 7.3, height: 8 },
    "(": { path: 17, width: 3, height: 17.5 },
    ")": { path: 16.6, width: 3.1, height: 17.1 },
    ",": { path: 3, width: 1.8, height: 3.5 },
    ".": { path: 1.5, width: 1, height: 2.5 },
    "/": { path: 13, width: 3.9, height: 13.8 },
    "<": { path: 13, width: 3.8, height: 13 },
    ">": { path: 13, width: 3.8, height: 13 },
    "?": { path: 15.3, width: 3, height: 14.3 },
    ";": { path: 4.5, width: 1.8, height: 10 },
    ":": { path: 2.9, width: 1, height: 9 },
    "\"": { path: 5, width: 3.5, height: 3.4 },
    "{": { path: 15.8, width: 3, height: 14 },
    "}": { path: 15.8, width: 3, height: 14 },
    "[": { path: 16.4, width: 3.3, height: 14.6 },
    "]": { path: 16.4, width: 3.3, height: 14.6 },
    "|": { path: 13.4, width: 1.5, height: 14.4 },
    "\\": { path: 13, width: 3.9, height: 13.6 },
    "=": { path: 6.8, width: 4.4, height: 2.1 },
    "+": { path: 8.5, width: 5, height: 5.5 },
  },
  Caveat: {
    "a": { path: 20, width: 7.2, height: 8.4 },
    "b": { path: 24.8, width: 5.1, height: 8.5 },
    "c": { path: 16.4, width: 7, height: 8.7 },
    "d": { path: 23.8, width: 7, height: 8.6 },
    "e": { path: 16.5, width: 4.2, height: 8.4 },
    "f": { path: 14, width: 4.5, height: 8.4 },
    "g": { path: 20.1, width: 7.2, height: 8.7 },
    "h": { path: 20.9, width: 7, height: 8.4 },
    "i": { path: 7.4, width: 1, height: 8.4 },
    "j": { path: 10.3, width: 2.6, height: 10.8 },
    "k": { path: 18.1, width: 6, height: 8.5 },
    "l": { path: 11, width: 4.6, height: 8.4 },
    "m": { path: 30.7, width: 9.3, height: 8.4 },
    "n": { path: 24.5, width: 7.2, height: 8.4 },
    "o": { path: 23.6, width: 8.4, height: 8.6 },
    "p": { path: 16.6, width: 4.8, height: 8.5 },
    "q": { path: 36.8, width: 15.6, height: 10.6 },
    "r": { path: 22.3, width: 6.7, height: 8.5 },
    "s": { path: 15.2, width: 5, height: 8.7 },
    "t": { path: 12.7, width: 6.3, height: 8.4 },
    "u": { path: 18.6, width: 7, height: 8.5 },
    "v": { path: 16.1, width: 7.3, height: 8.4 },
    "w": { path: 31.4, width: 11.4, height: 8.4 },
    "x": { path: 17.6, width: 6, height: 8.4 },
    "y": { path: 13.7, width: 6.6, height: 8.4 },
    "z": { path: 18.3, width: 5.9, height: 8.4 },
    "A": { path: 22, width: 7.8, height: 9.2 },
    "B": { path: 27.3, width: 5.5, height: 9.3 },
    "C": { path: 18.1, width: 7.6, height: 9.5 },
    "D": { path: 26.4, width: 7.6, height: 9.5 },
    "E": { path: 18.3, width: 4.6, height: 9.2 },
    "F": { path: 15.6, width: 4.9, height: 9.2 },
    "G": { path: 22.3, width: 7.9, height: 9.5 },
    "H": { path: 23, width: 7.6, height: 9.2 },
    "I": { path: 8.2, width: 1, height: 9.2 },
    "J": { path: 11.4, width: 2.8, height: 11.8 },
    "K": { path: 20, width: 6.6, height: 9.3 },
    "L": { path: 12.2, width: 5, height: 9.2 },
    "M": { path: 34, width: 10.2, height: 9.2 },
    "N": { path: 27.1, width: 7.9, height: 9.2 },
    "O": { path: 25.8, width: 9, height: 9.5 },
    "P": { path: 18.3, width: 5.2, height: 9.3 },
    "Q": { path: 40.5, width: 17.2, height: 11.7 },
    "R": { path: 24.6, width: 7.3, height: 9.3 },
    "S": { path: 16.7, width: 5.3, height: 9.5 },
    "T": { path: 14.1, width: 6.9, height: 9.2 },
    "U": { path: 20.3, width: 7.2, height: 9.3 },
    "V": { path: 17.8, width: 8, height: 9.2 },
    "W": { path: 34.8, width: 11.5, height: 9.2 },
    "X": { path: 19.5, width: 6.5, height: 9.2 },
    "Y": { path: 15.1, width: 7.1, height: 9.2 },
    "Z": { path: 20.2, width: 6.4, height: 9.2 },
    "1": { path: 10, width: 2.5, height: 9.2 },
    "2": { path: 18.3, width: 6.2, height: 9.3 },
    "3": { path: 18.7, width: 5.7, height: 9.5 },
    "4": { path: 20.8, width: 6.6, height: 9.2 },
    "5": { path: 17.3, width: 5.4, height: 9.3 },
    "6": { path: 20.2, width: 6, height: 9.5 },
    "7": { path: 13.6, width: 5.7, height: 9.2 },
    "8": { path: 25.9, width: 5.6, height: 9.5 },
    "9": { path: 20.2, width: 6, height: 9.5 },
    "0": { path: 22, width: 6.4, height: 9.5 },
    "!": { path: 6.1, width: 1, height: 8.5 },
    "@": { path: 40.6, width: 9.5, height: 9.7 },
    "#": { path: 28.4, width: 7.4, height: 9.2 },
    "$": { path: 27.6, width: 5.3, height: 11.9 },
    "%": { path: 31.1, width: 7.9, height: 9.5 },
    "&": { path: 28.2, width: 7, height: 9.1 },
    "*": { path: 11.4, width: 4.3, height: 4.8 },
    "(": { path: 10.8, width: 2.8, height: 10.9 },
    ")": { path: 10.8, width: 2.8, height: 10.9 },
    ",": { path: 1.6, width: 1.3, height: 2.5 },
    ".": { path: 1, width: 1, height: 1.7 },
    "/": { path: 9, width: 4.6, height: 9.3 },
    "<": { path: 8.2, width: 4.4, height: 5.7 },
    ">": { path: 8.2, width: 4.4, height: 5.7 },
    "?": { path: 11.1, width: 5, height: 9.5 },
    ";": { path: 2.3, width: 1.3, height: 6.4 },
    ":": { path: 1.3, width: 1, height: 5.5 },
    "\"": { path: 3.2, width: 2.8, height: 2.5 },
    "{": { path: 12.5, width: 3.4, height: 10.4 },
    "}": { path: 12.5, width: 3.4, height: 10.4 },
    "[": { path: 13.2, width: 2.8, height: 10.6 },
    "]": { path: 13.2, width: 2.8, height: 10.6 },
    "|": { path: 10.8, width: 1, height: 11.8 },
    "\\": { path: 9, width: 4.6, height: 9.3 },
    "=": { path: 8.6, width: 5.3, height: 3.5 },
    "+": { path: 7.5, width: 4.7, height: 4.7 },
  },
  Charmonman: {
    "a": { path: 21, width: 7.5, height: 6 },
    "b": { path: 28, width: 8, height: 13.5 },
    "c": { path: 13, width: 6, height: 5.5 },
    "d": { path: 31.5, width: 11, height: 13.5 },
    "e": { path: 16, width: 6, height: 5.5 },
    "f": { path: 33.5, width: 12, height: 19 },
    "g": { path: 30.5, width: 10, height: 13 },
    "h": { path: 33, width: 9.5, height: 14 },
    "i": { path: 12, width: 5, height: 8 },
    "j": { path: 15, width: 5, height: 8 },
    "k": { path: 35, width: 10, height: 14 },
    "l": { path: 29, width: 5.5, height: 13 },
    "m": { path: 27, width: 9.5, height: 5.5 },
    "n": { path: 19, width: 7, height: 5.5 },
    "o": { path: 19, width: 7, height: 6 },
    "p": { path: 31, width: 10, height: 15 },
    "q": { path: 35, width: 7, height: 13 },
    "r": { path: 12.5, width: 6.5, height: 5.5 },
    "s": { path: 10.5, width: 4.5, height: 5.5 },
    "t": { path: 18, width: 5.5, height: 9 },
    "u": { path: 18, width: 7, height: 5.5 },
    "v": { path: 13.5, width: 5.5, height: 6 },
    "w": { path: 23, width: 9.5, height: 5.5 },
    "x": { path: 17.5, width: 7.5, height: 7.5 },
    "y": { path: 25, width: 10.5, height: 11.5 },
    "z": { path: 24.5, width: 13, height: 9 },
    "A": { path: 28.5, width: 11, height: 9.5 },
    "B": { path: 33, width: 7, height: 9 },
    "C": { path: 22.5, width: 8, height: 9.5 },
    "D": { path: 29, width: 9.5, height: 9 },
    "E": { path: 26, width: 8, height: 9 },
    "F": { path: 19, width: 6.5, height: 9 },
    "G": { path: 38, width: 9, height: 14 },
    "H": { path: 29.5, width: 9.5, height: 10 },
    "I": { path: 12, width: 3.5, height: 9 },
    "J": { path: 24, width: 9.5, height: 11 },
    "K": { path: 36, width: 16.5, height: 11.5 },
    "L": { path: 19, width: 8, height: 9.5 },
    "M": { path: 48, width: 15.5, height: 10 },
    "N": { path: 30.5, width: 10.5, height: 10.5 },
    "O": { path: 25.5, width: 8.5, height: 9.5 },
    "P": { path: 23, width: 8, height: 9 },
    "Q": { path: 37, width: 10, height: 11 },
    "R": { path: 39.5, width: 16.5, height: 12 },
    "S": { path: 19.5, width: 6.5, height: 9 },
    "T": { path: 26, width: 11.5, height: 9.5 },
    "U": { path: 29.5, width: 10, height: 9 },
    "V": { path: 24, width: 10.5, height: 10.5 },
    "W": { path: 38, width: 13, height: 10 },
    "X": { path: 28, width: 9.5, height: 10.5 },
    "Y": { path: 22, width: 11.5, height: 9.5 },
    "Z": { path: 31.5, width: 12, height: 11 },
    "1": { path: 11.5, width: 3.5, height: 8 },
    "2": { path: 17, width: 5.5, height: 8 },
    "3": { path: 16.5, width: 5, height: 8 },
    "4": { path: 19.5, width: 5.5, height: 8 },
    "5": { path: 15, width: 6, height: 8 },
    "6": { path: 20.5, width: 5.5, height: 8 },
    "7": { path: 13.5, width: 5, height: 8 },
    "8": { path: 22, width: 5, height: 8 },
    "9": { path: 20.5, width: 5.5, height: 8 },
    "0": { path: 19.5, width: 5, height: 8 },
    "!": { path: 8, width: 2.5, height: 9 },
    "@": { path: 36, width: 8, height: 8 },
    "#": { path: 30, width: 6.5, height: 8.5 },
    "$": { path: 33, width: 6, height: 14.5 },
    "%": { path: 40, width: 11.5, height: 11.5 },
    "&": { path: 53, width: 11, height: 10.5 },
    "*": { path: 12.5, width: 4, height: 4 },
    "(": { path: 15, width: 4.5, height: 13 },
    ")": { path: 15, width: 4.5, height: 13 },
    ",": { path: 5, width: 3.5, height: 4 },
    ".": { path: 2, width: 1.5, height: 2 },
    "/": { path: 15.5, width: 8.5, height: 13.5 },
    "<": { path: 10.5, width: 5.5, height: 4.5 },
    ">": { path: 10.5, width: 5.5, height: 4.5 },
    "?": { path: 14, width: 4.5, height: 9.5 },
    ";": { path: 6, width: 4, height: 8 },
    ":": { path: 3, width: 2, height: 6 },
    "\"": { path: 9.5, width: 4, height: 4.5 },
    "{": { path: 19, width: 6, height: 13.5 },
    "}": { path: 19, width: 6, height: 13.5 },
    "[": { path: 17.5, width: 5.5, height: 12 },
    "]": { path: 17.5, width: 5.5, height: 12 },
    "|": { path: 19.5, width: 4, height: 19.5 },
    "\\": { path: 13.5, width: 3.5, height: 13.5 },
    "=": { path: 9.5, width: 5.5, height: 3 },
    "+": { path: 10, width: 5.5, height: 5.5 },
  },
  Comfortaa: {
    "a": { path: 20, width: 6.9, height: 6.9 },
    "b": { path: 22.8, width: 6.8, height: 9.6 },
    "c": { path: 13.5, width: 5.9, height: 6.8 },
    "d": { path: 22.8, width: 6.8, height: 9.6 },
    "e": { path: 21.2, width: 6.4, height: 5.8 },
    "f": { path: 10.8, width: 3, height: 9.6 },
    "g": { path: 27.2, width: 6.8, height: 9.4 },
    "h": { path: 19, width: 6.2, height: 9.6 },
    "i": { path: 7.8, width: 1, height: 9.6 },
    "j": { path: 12, width: 3.4, height: 12.4 },
    "k": { path: 17.5, width: 5.2, height: 9.6 },
    "l": { path: 10.6, width: 2.5, height: 9.6 },
    "m": { path: 24.2, width: 9.6, height: 6.8 },
    "n": { path: 16, width: 6.2, height: 6.9 },
    "o": { path: 19.5, width: 6.9, height: 6.9 },
    "p": { path: 22.8, width: 6.8, height: 9.6 },
    "q": { path: 22.8, width: 6.8, height: 9.6 },
    "r": { path: 9.6, width: 4.4, height: 6.8 },
    "s": { path: 17.1, width: 5.5, height: 6.9 },
    "t": { path: 16.3, width: 6.2, height: 3.9 },
    "u": { path: 12.9, width: 5.5, height: 6.8 },
    "v": { path: 13.9, width: 5.5, height: 6.8 },
    "w": { path: 25.8, width: 8.8, height: 6.8 },
    "x": { path: 15.1, width: 5.8, height: 6.8 },
    "y": { path: 15.9, width: 5.5, height: 9.7 },
    "z": { path: 16.6, width: 5.5, height: 6.9 },
    "A": { path: 24.9, width: 8.2, height: 9.6 },
    "B": { path: 30.7, width: 6.6, height: 9.6 },
    "C": { path: 20.1, width: 7.9, height: 9.6 },
    "D": { path: 26.7, width: 7.4, height: 9.6 },
    "E": { path: 25.3, width: 6.6, height: 9.6 },
    "F": { path: 19.6, width: 6.6, height: 9.6 },
    "G": { path: 25.5, width: 7.9, height: 9.6 },
    "H": { path: 23.1, width: 7.8, height: 9.6 },
    "I": { path: 9.6, width: 1, height: 9.6 },
    "J": { path: 14.8, width: 5.8, height: 9.6 },
    "K": { path: 22.2, width: 6.3, height: 9.6 },
    "L": { path: 13.9, width: 6.3, height: 9.6 },
    "M": { path: 36.2, width: 9.6, height: 9.6 },
    "N": { path: 29.2, width: 7.6, height: 9.6 },
    "O": { path: 27.1, width: 9.6, height: 9.6 },
    "P": { path: 22, width: 6.6, height: 9.6 },
    "Q": { path: 32, width: 9.6, height: 9.6 },
    "R": { path: 24.8, width: 6.1, height: 9.6 },
    "S": { path: 21.9, width: 6.6, height: 9.6 },
    "T": { path: 15.5, width: 7.6, height: 9.6 },
    "U": { path: 21.7, width: 7.6, height: 9.6 },
    "V": { path: 19.7, width: 8.2, height: 9.6 },
    "W": { path: 36.9, width: 10.9, height: 9.6 },
    "X": { path: 22.5, width: 7.6, height: 9.6 },
    "Y": { path: 16, width: 7.8, height: 9.6 },
    "Z": { path: 25.5, width: 7.8, height: 9.6 },
    "1": { path: 10.6, width: 2.9, height: 9.6 },
    "2": { path: 20, width: 5.8, height: 9.6 },
    "3": { path: 21, width: 5.9, height: 9.6 },
    "4": { path: 23.8, width: 7.2, height: 9.6 },
    "5": { path: 23.2, width: 6.2, height: 9.6 },
    "6": { path: 19.9, width: 5.7, height: 9.6 },
    "7": { path: 14.5, width: 5.9, height: 9.6 },
    "8": { path: 23.5, width: 5.3, height: 9.6 },
    "9": { path: 19.7, width: 5.7, height: 9.6 },
    "0": { path: 23, width: 5.8, height: 9.6 },
    "!": { path: 8.2, width: 1, height: 9.6 },
    "@": { path: 54.4, width: 12.3, height: 12.5 },
    "#": { path: 32.2, width: 7.8, height: 10 },
    "$": { path: 31.4, width: 6.2, height: 12.2 },
    "%": { path: 34.5, width: 9.6, height: 9.6 },
    "&": { path: 31.1, width: 8.7, height: 9.6 },
    "*": { path: 12.9, width: 5.4, height: 5.3 },
    "(": { path: 12.9, width: 2.4, height: 12.4 },
    ")": { path: 12.9, width: 2.4, height: 12.4 },
    ",": { path: 2, width: 1.4, height: 2.8 },
    ".": { path: 1.2, width: 1, height: 2.2 },
    "/": { path: 11.3, width: 4.8, height: 10.6 },
    "<": { path: 22.3, width: 13.1, height: 6.8 },
    ">": { path: 22.3, width: 13.1, height: 6.8 },
    "?": { path: 13.5, width: 5.7, height: 9.6 },
    ";": { path: 4.3, width: 1.5, height: 8.4 },
    ":": { path: 3.4, width: 1, height: 6.9 },
    "\"": { path: 5, width: 3.1, height: 3.1 },
    "{": { path: 16.3, width: 3.9, height: 12 },
    "}": { path: 16.3, width: 3.9, height: 12 },
    "[": { path: 14.5, width: 2.9, height: 12.4 },
    "]": { path: 14.5, width: 2.9, height: 12.4 },
    "|": { path: 11.3, width: 1, height: 12.3 },
    "\\": { path: 10.3, width: 4.8, height: 10.5 },
    "=": { path: 8.5, width: 5.3, height: 3.8 },
    "+": { path: 9.5, width: 5.7, height: 5.7 },
  },
  CutiveMono: {
    "a": { path: 8.8, width: 5.4, height: 5.5 },
    "b": { path: 19.2, width: 6.4, height: 7.7 },
    "c": { path: 12, width: 5.2, height: 5.5 },
    "d": { path: 20.5, width: 6.5, height: 7.7 },
    "e": { path: 16.3, width: 5.1, height: 5.5 },
    "f": { path: 16.3, width: 4.8, height: 7.7 },
    "g": { path: 23, width: 5.9, height: 7.9 },
    "h": { path: 19.2, width: 6.7, height: 7.6 },
    "i": { path: 12, width: 5.5, height: 7.8 },
    "j": { path: 12, width: 3.9, height: 9.6 },
    "k": { path: 20.9, width: 6.4, height: 7.6 },
    "l": { path: 13.5, width: 5.6, height: 7.6 },
    "m": { path: 20, width: 8.7, height: 5.4 },
    "n": { path: 17, width: 7, height: 5.3 },
    "o": { path: 14, width: 5.4, height: 5.5 },
    "p": { path: 21.3, width: 6.3, height: 7.6 },
    "q": { path: 20, width: 5.8, height: 7.6 },
    "r": { path: 13.6, width: 5.2, height: 5.4 },
    "s": { path: 17.3, width: 4.8, height: 5.7 },
    "t": { path: 12, width: 5, height: 6.9 },
    "u": { path: 15.9, width: 7.3, height: 5.4 },
    "v": { path: 12.1, width: 6.2, height: 5 },
    "w": { path: 20, width: 7.5, height: 5 },
    "x": { path: 14, width: 5.9, height: 5.2 },
    "y": { path: 15.5, width: 6, height: 7.3 },
    "z": { path: 16.1, width: 5, height: 5.2 },
    "A": { path: 24, width: 7.3, height: 7.5 },
    "B": { path: 24, width: 5.9, height: 7.5 },
    "C": { path: 18.5, width: 6.5, height: 7.8 },
    "D": { path: 20.8, width: 6.3, height: 7.5 },
    "E": { path: 25, width: 6.6, height: 7.5 },
    "F": { path: 21.6, width: 6.6, height: 7.5 },
    "G": { path: 25, width: 7, height: 7.8 },
    "H": { path: 24, width: 6.6, height: 7.5 },
    "I": { path: 14.5, width: 5, height: 7.5 },
    "J": { path: 14.5, width: 6, height: 7.5 },
    "K": { path: 28, width: 7.9, height: 7.5 },
    "L": { path: 17.7, width: 7.9, height: 7.5 },
    "M": { path: 30, width: 7.5, height: 7.5 },
    "N": { path: 26, width: 6.9, height: 7.5 },
    "O": { path: 19.3, width: 6.6, height: 7.7 },
    "P": { path: 20.6, width: 6, height: 7.5 },
    "Q": { path: 22.6, width: 6.6, height: 9.1 },
    "R": { path: 23.9, width: 6.6, height: 7.5 },
    "S": { path: 21.1, width: 5.2, height: 7.7 },
    "T": { path: 18.8, width: 6.6, height: 7.5 },
    "U": { path: 20, width: 7.4, height: 7.6 },
    "V": { path: 17.4, width: 6.7, height: 7.6 },
    "W": { path: 26.8, width: 7.2, height: 7.5 },
    "X": { path: 22.6, width: 6.5, height: 7.5 },
    "Y": { path: 17.9, width: 6.3, height: 7.5 },
    "Z": { path: 19.7, width: 5.3, height: 7.5 },
    "1": { path: 13.6, width: 5.6, height: 7.5 },
    "2": { path: 16.4, width: 4.7, height: 7.6 },
    "3": { path: 14.8, width: 4.6, height: 7.7 },
    "4": { path: 19.2, width: 5.4, height: 7.7 },
    "5": { path: 15.7, width: 4.6, height: 7.6 },
    "6": { path: 18.2, width: 5.1, height: 8.1 },
    "7": { path: 11.8, width: 4.7, height: 7.5 },
    "8": { path: 21.7, width: 5, height: 7.7 },
    "9": { path: 17.7, width: 5, height: 7.8 },
    "0": { path: 17.1, width: 5.1, height: 7.7 },
    "!": { path: 5.6, width: 1, height: 7.5 },
    "@": { path: 28.1, width: 7.1, height: 7.7 },
    "#": { path: 23.8, width: 5.2, height: 8.8 },
    "$": { path: 29.4, width: 5.2, height: 9.3 },
    "%": { path: 24.1, width: 6.2, height: 8.7 },
    "&": { path: 24.8, width: 6.2, height: 8.7 },
    "*": { path: 11, width: 4.8, height: 4.1 },
    "(": { path: 8.3, width: 2.1, height: 8.9 },
    ")": { path: 8.3, width: 2.1, height: 8.9 },
    ",": { path: 2.1, width: 1.7, height: 2.6 },
    ".": { path: 1.3, width: 1, height: 2.3 },
    "/": { path: 8.7, width: 4.9, height: 8.7 },
    "<": { path: 11.4, width: 6.2, height: 5.3 },
    ">": { path: 11.4, width: 6.2, height: 5.3 },
    "?": { path: 9.1, width: 4, height: 7.6 },
    ";": { path: 3.3, width: 1.6, height: 6.6 },
    ":": { path: 2.5, width: 1, height: 5.3 },
    "\"": { path: 4.3, width: 3.5, height: 2.7 },
    "{": { path: 12, width: 3.3, height: 9.5 },
    "}": { path: 12, width: 3.3, height: 9.5 },
    "[": { path: 10.8, width: 2.3, height: 9.3 },
    "]": { path: 10.8, width: 2.3, height: 9.3 },
    "|": { path: 7.5, width: 1, height: 8.5 },
    "\\": { path: 8.7, width: 4.9, height: 8.7 },
    "=": { path: 6.4, width: 4.2, height: 3 },
    "+": { path: 7, width: 4.5, height: 4.5 },
  },
  GiveYouGlory: {
    "a": { path: 22.3, width: 7, height: 7.9 },
    "b": { path: 21.5, width: 5.9, height: 8.5 },
    "c": { path: 11, width: 4.8, height: 6.3 },
    "d": { path: 20.4, width: 4.5, height: 8.8 },
    "e": { path: 14.2, width: 5.1, height: 6.5 },
    "f": { path: 15.7, width: 5.2, height: 9.2 },
    "g": { path: 32.7, width: 4.7, height: 13.3 },
    "h": { path: 18.8, width: 6, height: 8.5 },
    "i": { path: 6.2, width: 10, height: 8.9 },
    "j": { path: 13.4, width: 3.5, height: 13.2 },
    "k": { path: 20.9, width: 8.1, height: 8.1 },
    "l": { path: 8.5, width: 1.1, height: 9.3 },
    "m": { path: 29.1, width: 10.1, height: 7 },
    "n": { path: 17.5, width: 6.1, height: 6.8 },
    "o": { path: 12.5, width: 3.8, height: 5.6 },
    "p": { path: 17.8, width: 4.6, height: 9.3 },
    "q": { path: 31.6, width: 6.5, height: 13 },
    "r": { path: 20.3, width: 6, height: 7.8 },
    "s": { path: 12.7, width: 3.7, height: 7.2 },
    "t": { path: 12.8, width: 5.3, height: 9.1 },
    "u": { path: 19, width: 8.3, height: 6.9 },
    "v": { path: 11.5, width: 6.1, height: 5.8 },
    "w": { path: 23, width: 8.7, height: 6.5 },
    "x": { path: 15, width: 4.9, height: 7.8 },
    "y": { path: 34.1, width: 6.7, height: 13.5 },
    "z": { path: 22.7, width: 7.2, height: 7.5 },
    "A": { path: 29.3, width: 9.8, height: 9.7 },
    "B": { path: 32.6, width: 7.9, height: 9.7 },
    "C": { path: 16.1, width: 6.7, height: 9 },
    "D": { path: 22.9, width: 6.8, height: 8.9 },
    "E": { path: 28.1, width: 9.2, height: 9.3 },
    "F": { path: 19.5, width: 7.9, height: 8.9 },
    "G": { path: 27.8, width: 8.8, height: 9.9 },
    "H": { path: 23.3, width: 8.3, height: 9.1 },
    "I": { path: 7, width: 0.9, height: 7.8 },
    "J": { path: 24.4, width: 11.2, height: 9.2 },
    "K": { path: 25.8, width: 9.9, height: 9.1 },
    "L": { path: 12.5, width: 6.9, height: 7.4 },
    "M": { path: 34.2, width: 11, height: 9.2 },
    "N": { path: 25.5, width: 9.3, height: 9.6 },
    "O": { path: 22.9, width: 7.5, height: 9.4 },
    "P": { path: 21.3, width: 6.3, height: 10.3 },
    "Q": { path: 30.2, width: 7.5, height: 10.9 },
    "R": { path: 27.5, width: 8.1, height: 10 },
    "S": { path: 20.8, width: 5.1, height: 9.6 },
    "T": { path: 17.1, width: 9.9, height: 8.9 },
    "U": { path: 23.9, width: 9.6, height: 7.6 },
    "V": { path: 14.1, width: 6.3, height: 7.1 },
    "W": { path: 26.4, width: 10.4, height: 7.9 },
    "X": { path: 19.2, width: 6.6, height: 8.4 },
    "Y": { path: 17.8, width: 7, height: 9.4 },
    "Z": { path: 28.9, width: 8.9, height: 9.4 },
    "1": { path: 6, width: 1.3, height: 6.8 },
    "2": { path: 16.5, width: 7.6, height: 7.2 },
    "3": { path: 19.3, width: 5, height: 7.8 },
    "4": { path: 17.9, width: 7.1, height: 6.9 },
    "5": { path: 17.9, width: 5.2, height: 8 },
    "6": { path: 16.9, width: 6.1, height: 6.7 },
    "7": { path: 17.6, width: 7.2, height: 8.1 },
    "8": { path: 21.2, width: 5.5, height: 9.3 },
    "9": { path: 20.1, width: 4.4, height: 8.7 },
    "0": { path: 17, width: 4.7, height: 8.1 },
    "!": { path: 5.5, width: 1.3, height: 10.1 },
    "@": { path: 52.8, width: 12.8, height: 13.4 },
    "#": { path: 37, width: 11.7, height: 8.7 },
    "$": { path: 35.5, width: 5.6, height: 12.7 },
    "%": { path: 22.8, width: 8.5, height: 7.6 },
    "&": { path: 19.2, width: 6.6, height: 6.3 },
    "*": { path: 11.1, width: 5.5, height: 4.7 },
    "(": { path: 11.5, width: 3, height: 11.4 },
    ")": { path: 11.3, width: 2.2, height: 11.6 },
    ",": { path: 5, width: 1.8, height: 5.4 },
    ".": { path: 1.1, width: 1.2, height: 1.8 },
    "/": { path: 8.2, width: 5, height: 7.8 },
    "<": { path: 11.9, width: 7.1, height: 5.4 },
    ">": { path: 9, width: 4.9, height: 5.5 },
    "?": { path: 13.8, width: 6.2, height: 10.7 },
    ";": { path: 5.2, width: 1.8, height: 9.1 },
    ":": { path: 2.2, width: 2, height: 4.7 },
    "\"": { path: 3.3, width: 2.7, height: 2.7 },
    "{": { path: 19.1, width: 6.1, height: 11.9 },
    "}": { path: 19.5, width: 6.5, height: 13 },
    "[": { path: 18.7, width: 6.9, height: 11.4 },
    "]": { path: 19.6, width: 6.6, height: 12.2 },
    "|": { path: 7.6, width: 1.8, height: 8.4 },
    "\\": { path: 8.8, width: 5.6, height: 8.1 },
    "=": { path: 7.1, width: 4.6, height: 2.8 },
    "+": { path: 13, width: 6.9, height: 7.5 },
  },
  Gruppo: {
    "a": { path: 29.7, width: 5.9, height: 5.5 },
    "b": { path: 19.9, width: 6.2, height: 7.6 },
    "c": { path: 13.1, width: 5.8, height: 5.2 },
    "d": { path: 19.9, width: 6.2, height: 7.6 },
    "e": { path: 48.7, width: 5.9, height: 5.4 },
    "f": { path: 13.1, width: 4.6, height: 7.3 },
    "g": { path: 22.5, width: 6.1, height: 7.3 },
    "h": { path: 14.9, width: 5.8, height: 7.3 },
    "i": { path: 5.8, width: 1, height: 7.3 },
    "j": { path: 5.8, width: 1, height: 7.3 },
    "k": { path: 14.1, width: 5, height: 7.3 },
    "l": { path: 6.3, width: 1, height: 7.3 },
    "m": { path: 18.2, width: 8.3, height: 5.3 },
    "n": { path: 12.1, width: 5.7, height: 5.3 },
    "o": { path: 15.2, width: 6.3, height: 5.2 },
    "p": { path: 18.8, width: 6.2, height: 7.6 },
    "q": { path: 18.8, width: 6.2, height: 7.6 },
    "r": { path: 7.5, width: 4.1, height: 5.2 },
    "s": { path: 18.1, width: 6.1, height: 5.3 },
    "t": { path: 12.8, width: 4.7, height: 7 },
    "u": { path: 12.5, width: 5.7, height: 5.1 },
    "v": { path: 10.5, width: 5.8, height: 5 },
    "w": { path: 18.5, width: 7.4, height: 5.1 },
    "x": { path: 12, width: 5.4, height: 5 },
    "y": { path: 18.2, width: 5.7, height: 7.1 },
    "z": { path: 15, width: 5.7, height: 5 },
    "A": { path: 19, width: 7.9, height: 7.2 },
    "B": { path: 26.9, width: 7.2, height: 7.2 },
    "C": { path: 18.2, width: 8.2, height: 7.2 },
    "D": { path: 25.5, width: 8.7, height: 7.2 },
    "E": { path: 22.8, width: 6.6, height: 7.2 },
    "F": { path: 17.3, width: 6.9, height: 7.2 },
    "G": { path: 25, width: 8.7, height: 7.2 },
    "H": { path: 18.4, width: 6.8, height: 7.2 },
    "I": { path: 16.1, width: 5.9, height: 7.2 },
    "J": { path: 10.7, width: 5.8, height: 7.2 },
    "K": { path: 16.2, width: 6.2, height: 7.2 },
    "L": { path: 12.4, width: 6.2, height: 7.2 },
    "M": { path: 28, width: 9.1, height: 7.2 },
    "N": { path: 22.5, width: 7.6, height: 7.2 },
    "O": { path: 22.8, width: 9.2, height: 7.2 },
    "P": { path: 19, width: 6.4, height: 7.2 },
    "Q": { path: 24.8, width: 9.2, height: 8.3 },
    "R": { path: 22.8, width: 6.3, height: 7.2 },
    "S": { path: 24.5, width: 8.3, height: 7.4 },
    "T": { path: 13.3, width: 8.4, height: 7.2 },
    "U": { path: 17.4, width: 8.2, height: 7.2 },
    "V": { path: 14.5, width: 8.4, height: 7.2 },
    "W": { path: 26.5, width: 10, height: 7.2 },
    "X": { path: 18.1, width: 7.6, height: 7.2 },
    "Y": { path: 12.1, width: 7.2, height: 7.2 },
    "Z": { path: 22.7, width: 7.8, height: 7.2 },
    "1": { path: 7.6, width: 2.4, height: 7.2 },
    "2": { path: 15.8, width: 5.2, height: 7.2 },
    "3": { path: 16.7, width: 4.9, height: 7.2 },
    "4": { path: 16.5, width: 6.2, height: 7.2 },
    "5": { path: 15.3, width: 4.9, height: 7.2 },
    "6": { path: 20, width: 5.6, height: 7.2 },
    "7": { path: 6.6, width: 3.6, height: 7.2 },
    "8": { path: 21, width: 5.3, height: 7.2 },
    "9": { path: 20, width: 5.6, height: 7.2 },
    "0": { path: 18.2, width: 5.8, height: 7.2 },
    "!": { path: 5.7, width: 1, height: 7.2 },
    "@": { path: 33.3, width: 7.6, height: 7.5 },
    "#": { path: 19.2, width: 4.6, height: 7.2 },
    "$": { path: 12, width: 8.2, height: 9.3 },
    "%": { path: 23, width: 7.3, height: 7.2 },
    "&": { path: 23, width: 7.2, height: 7.6 },
    "*": { path: 12.8, width: 5.2, height: 4.8 },
    "(": { path: 20.8, width: 5.5, height: 9.2 },
    ")": { path: 20.8, width: 5.5, height: 9.2 },
    ",": { path: 1.4, width: 1.3, height: 2.3 },
    ".": { path: 1, width: 1, height: 1.9 },
    "/": { path: 8.5, width: 3.8, height: 8 },
    "<": { path: 7.6, width: 4.5, height: 4 },
    ">": { path: 7.6, width: 4.5, height: 4 },
    "?": { path: 11.3, width: 5.1, height: 7.6 },
    ";": { path: 3.4, width: 1.9, height: 5.3 },
    ":": { path: 2.4, width: 1, height: 4.6 },
    "\"": { path: 4, width: 3.1, height: 3.1 },
    "{": { path: 12.7, width: 3.9, height: 9.3 },
    "}": { path: 12.7, width: 3.9, height: 9.3 },
    "[": { path: 10.8, width: 2.3, height: 9.7 },
    "]": { path: 10.8, width: 2.3, height: 9.7 },
    "|": { path: 8.6, width: 1, height: 9.6 },
    "\\": { path: 7, width: 3.6, height: 7.4 },
    "=": { path: 6.5, width: 4.3, height: 2 },
    "+": { path: 7.3, width: 4.6, height: 4.6 },
  },
  JuliusSansOne: {
    "a": { path: 20, width: 7.2, height: 8.4 },
    "b": { path: 24.8, width: 5.1, height: 8.5 },
    "c": { path: 16.4, width: 7, height: 8.7 },
    "d": { path: 23.8, width: 7, height: 8.6 },
    "e": { path: 16.5, width: 4.2, height: 8.4 },
    "f": { path: 14, width: 4.5, height: 8.4 },
    "g": { path: 20.1, width: 7.2, height: 8.7 },
    "h": { path: 20.9, width: 7, height: 8.4 },
    "i": { path: 7.4, width: 1, height: 8.4 },
    "j": { path: 10.3, width: 2.6, height: 10.8 },
    "k": { path: 18.1, width: 6, height: 8.5 },
    "l": { path: 11, width: 4.6, height: 8.4 },
    "m": { path: 30.7, width: 9.3, height: 8.4 },
    "n": { path: 24.5, width: 7.2, height: 8.4 },
    "o": { path: 23.6, width: 8.4, height: 8.6 },
    "p": { path: 16.6, width: 4.8, height: 8.5 },
    "q": { path: 36.8, width: 15.6, height: 10.6 },
    "r": { path: 22.3, width: 6.7, height: 8.5 },
    "s": { path: 15.2, width: 5, height: 8.7 },
    "t": { path: 12.7, width: 6.3, height: 8.4 },
    "u": { path: 18.6, width: 7, height: 8.5 },
    "v": { path: 16.1, width: 7.3, height: 8.4 },
    "w": { path: 31.4, width: 11.4, height: 8.4 },
    "x": { path: 17.6, width: 6, height: 8.4 },
    "y": { path: 13.7, width: 6.6, height: 8.4 },
    "z": { path: 18.3, width: 5.9, height: 8.4 },
    "A": { path: 22, width: 7.8, height: 9.2 },
    "B": { path: 27.3, width: 5.5, height: 9.3 },
    "C": { path: 18.1, width: 7.6, height: 9.5 },
    "D": { path: 26.4, width: 7.6, height: 9.5 },
    "E": { path: 18.3, width: 4.6, height: 9.2 },
    "F": { path: 15.6, width: 4.9, height: 9.2 },
    "G": { path: 22.3, width: 7.9, height: 9.5 },
    "H": { path: 23, width: 7.6, height: 9.2 },
    "I": { path: 8.2, width: 1, height: 9.2 },
    "J": { path: 11.4, width: 2.8, height: 11.8 },
    "K": { path: 20, width: 6.6, height: 9.3 },
    "L": { path: 12.2, width: 5, height: 9.2 },
    "M": { path: 34, width: 10.2, height: 9.2 },
    "N": { path: 27.1, width: 7.9, height: 9.2 },
    "O": { path: 25.8, width: 9, height: 9.5 },
    "P": { path: 18.3, width: 5.2, height: 9.3 },
    "Q": { path: 40.5, width: 17.2, height: 11.7 },
    "R": { path: 24.6, width: 7.3, height: 9.3 },
    "S": { path: 16.7, width: 5.3, height: 9.5 },
    "T": { path: 14.1, width: 6.9, height: 9.2 },
    "U": { path: 20.3, width: 7.2, height: 9.3 },
    "V": { path: 17.8, width: 8, height: 9.2 },
    "W": { path: 34.8, width: 11.5, height: 9.2 },
    "X": { path: 19.5, width: 6.5, height: 9.2 },
    "Y": { path: 15.1, width: 7.1, height: 9.2 },
    "Z": { path: 20.2, width: 6.4, height: 9.2 },
    "1": { path: 10, width: 2.5, height: 9.2 },
    "2": { path: 18.3, width: 6.2, height: 9.3 },
    "3": { path: 18.7, width: 5.7, height: 9.5 },
    "4": { path: 20.8, width: 6.6, height: 9.2 },
    "5": { path: 17.3, width: 5.4, height: 9.3 },
    "6": { path: 20.2, width: 6, height: 9.5 },
    "7": { path: 13.6, width: 5.7, height: 9.2 },
    "8": { path: 25.9, width: 5.6, height: 9.5 },
    "9": { path: 20.2, width: 6, height: 9.5 },
    "0": { path: 22, width: 6.4, height: 9.5 },
    "!": { path: 6.1, width: 1, height: 8.5 },
    "@": { path: 40.6, width: 9.5, height: 9.7 },
    "#": { path: 28.4, width: 7.4, height: 9.2 },
    "$": { path: 27.6, width: 5.3, height: 11.9 },
    "%": { path: 31.1, width: 7.9, height: 9.5 },
    "&": { path: 28.2, width: 7, height: 9.1 },
    "*": { path: 11.4, width: 4.3, height: 4.8 },
    "(": { path: 10.8, width: 2.8, height: 10.9 },
    ")": { path: 10.8, width: 2.8, height: 10.9 },
    ",": { path: 1.6, width: 1.3, height: 2.5 },
    ".": { path: 1, width: 1, height: 1.7 },
    "/": { path: 9, width: 4.6, height: 9.3 },
    "<": { path: 8.2, width: 4.4, height: 5.7 },
    ">": { path: 8.2, width: 4.4, height: 5.7 },
    "?": { path: 11.1, width: 5, height: 9.5 },
    ";": { path: 2.3, width: 1.3, height: 6.4 },
    ":": { path: 1.3, width: 1, height: 5.5 },
    "\"": { path: 3.2, width: 2.8, height: 2.5 },
    "{": { path: 12.5, width: 3.4, height: 10.4 },
    "}": { path: 12.5, width: 3.4, height: 10.4 },
    "[": { path: 13.2, width: 2.8, height: 10.6 },
    "]": { path: 13.2, width: 2.8, height: 10.6 },
    "|": { path: 10.8, width: 1, height: 11.8 },
    "\\": { path: 9, width: 4.6, height: 9.3 },
    "=": { path: 8.6, width: 5.3, height: 3.5 },
    "+": { path: 7.5, width: 4.7, height: 4.7 },
  },
  MrDafoe: {
    "a": { path: 21, width: 8, height: 4.5 },
    "b": { path: 23.5, width: 7.5, height: 8.5 },
    "c": { path: 16, width: 7, height: 4.5 },
    "d": { path: 27.5, width: 9.5, height: 8.5 },
    "e": { path: 17.5, width: 7, height: 4.5 },
    "f": { path: 28, width: 8, height: 13 },
    "g": { path: 27, width: 8.5, height: 9 },
    "h": { path: 25, width: 8, height: 8 },
    "i": { path: 12, width: 6, height: 6.5 },
    "j": { path: 19, width: 9.5, height: 11 },
    "k": { path: 21.5, width: 7, height: 8.5 },
    "l": { path: 24.5, width: 7, height: 8 },
    "m": { path: 30.5, width: 10.5, height: 4.5 },
    "n": { path: 21, width: 8.5, height: 4.5 },
    "o": { path: 19, width: 7, height: 5 },
    "p": { path: 23.5, width: 9.5, height: 10 },
    "q": { path: 25.5, width: 7, height: 9 },
    "r": { path: 17, width: 7.5, height: 4.5 },
    "s": { path: 16, width: 6.5, height: 5 },
    "t": { path: 23, width: 8.5, height: 7.5 },
    "u": { path: 18.5, width: 8, height: 4.5 },
    "v": { path: 11.5, width: 5, height: 5 },
    "w": { path: 18, width: 7, height: 5 },
    "x": { path: 17.5, width: 8.5, height: 4.5 },
    "y": { path: 26, width: 9, height: 9 },
    "z": { path: 11.5, width: 4.5, height: 4.5 },
    "A": { path: 30.5, width: 9, height: 10 },
    "B": { path: 40, width: 9.5, height: 8.5 },
    "C": { path: 26, width: 10, height: 9 },
    "D": { path: 43, width: 11, height: 9 },
    "E": { path: 32, width: 11, height: 8 },
    "F": { path: 26, width: 11, height: 8 },
    "G": { path: 46, width: 12, height: 13 },
    "H": { path: 32.5, width: 11, height: 9 },
    "I": { path: 27, width: 9.5, height: 9.5 },
    "J": { path: 35.5, width: 11, height: 12 },
    "K": { path: 26, width: 10.5, height: 9 },
    "L": { path: 33, width: 14, height: 10.5 },
    "M": { path: 46, width: 14, height: 8 },
    "N": { path: 35.5, width: 16, height: 11 },
    "O": { path: 29, width: 9.5, height: 9 },
    "P": { path: 32, width: 10, height: 9 },
    "Q": { path: 37, width: 11, height: 9 },
    "R": { path: 38.5, width: 11, height: 9 },
    "S": { path: 28, width: 11.5, height: 8.5 },
    "T": { path: 20.5, width: 10, height: 8 },
    "U": { path: 37.5, width: 11, height: 10.5 },
    "V": { path: 29, width: 11.5, height: 9 },
    "W": { path: 45.5, width: 14, height: 9 },
    "X": { path: 34, width: 13, height: 10.5 },
    "Y": { path: 53.5, width: 13, height: 13 },
    "Z": { path: 28, width: 10.5, height: 8 },
    "1": { path: 14, width: 6, height: 9.5 },
    "2": { path: 31.5, width: 8.5, height: 8.5 },
    "3": { path: 24.5, width: 8, height: 8.5 },
    "4": { path: 28, width: 8.5, height: 10 },
    "5": { path: 24, width: 8, height: 8.5 },
    "6": { path: 25, width: 7, height: 9.5 },
    "7": { path: 21.5, width: 8, height: 8 },
    "8": { path: 27.5, width: 8.5, height: 8.5 },
    "9": { path: 27, width: 8, height: 10.5 },
    "0": { path: 25.5, width: 8.5, height: 9 },
    "!": { path: 11, width: 8.5, height: 9 },
    "@": { path: 31, width: 7.5, height: 6.5 },
    "#": { path: 28.5, width: 9, height: 7.5 },
    "$": { path: 33, width: 11, height: 11.5 },
    "%": { path: 34, width: 8.5, height: 8.5 },
    "&": { path: 37.5, width: 9.5, height: 12.5 },
    "*": { path: 11.5, width: 4, height: 4 },
    "(": { path: 13.5, width: 6.5, height: 10.5 },
    ")": { path: 13.5, width: 6.5, height: 10.5 },
    ",": { path: 2.8, width: 1.5, height: 2.5 },
    ".": { path: 2.5, width: 2, height: 2 },
    "/": { path: 12, width: 8.5, height: 8.5 },
    "<": { path: 9, width: 5, height: 6 },
    ">": { path: 9, width: 5, height: 6 },
    "?": { path: 14, width: 6, height: 8.5 },
    ";": { path: 5.5, width: 5, height: 6.5 },
    ":": { path: 5, width: 4.5, height: 6 },
    "\"": { path: 5.5, width: 3.5, height: 2.5 },
    "{": { path: 13.5, width: 7, height: 10.5 },
    "}": { path: 13.5, width: 7, height: 10.5 },
    "[": { path: 15, width: 7.5, height: 8.5 },
    "]": { path: 15, width: 7.5, height: 8.5 },
    "|": { path: 18.5, width: 11.5, height: 12.5 },
    "\\": { path: 9, width: 2.5, height: 8.5 },
    "=": { path: 9.5, width: 6.5, height: 3 },
    "+": { path: 13, width: 6.5, height: 5.5 },
  },
  PoiretOne: {
    "a": { path: 20, width: 6.3, height: 6.4 },
    "b": { path: 22.8, width: 6.3, height: 9.7 },
    "c": { path: 13.2, width: 5.9, height: 6.4 },
    "d": { path: 22.8, width: 6.3, height: 9.7 },
    "e": { path: 17.2, width: 5.6, height: 6.3 },
    "f": { path: 10.3, width: 3, height: 8.2 },
    "g": { path: 25.9, width: 6.3, height: 7.7 },
    "h": { path: 17.1, width: 5.5, height: 9.7 },
    "i": { path: 5.8, width: 1, height: 8 },
    "j": { path: 9.5, width: 2.8, height: 10.4 },
    "k": { path: 15.3, width: 3.8, height: 9.6 },
    "l": { path: 9.6, width: 2.8, height: 9.4 },
    "m": { path: 20.8, width: 8.2, height: 6 },
    "n": { path: 13.7, width: 5.4, height: 6 },
    "o": { path: 18.2, width: 6.3, height: 6.3 },
    "p": { path: 20.8, width: 6.3, height: 8.6 },
    "q": { path: 20.8, width: 6.3, height: 8.6 },
    "r": { path: 8.6, width: 3.8, height: 6 },
    "s": { path: 11, width: 4.1, height: 6.4 },
    "t": { path: 10.1, width: 2.8, height: 8.2 },
    "u": { path: 13.5, width: 5.5, height: 6.2 },
    "v": { path: 11.2, width: 5.7, height: 6.2 },
    "w": { path: 22.7, width: 8.1, height: 5.9 },
    "x": { path: 11.7, width: 4.8, height: 5.9 },
    "y": { path: 19.1, width: 5.6, height: 8.3 },
    "z": { path: 12, width: 4.5, height: 5.9 },
    "A": { path: 54.7, width: 9.5, height: 9.8 },
    "B": { path: 22.4, width: 4, height: 9.8 },
    "C": { path: 19.4, width: 7.9, height: 10.1 },
    "D": { path: 23.1, width: 5.4, height: 9.8 },
    "E": { path: 17.3, width: 4, height: 9.8 },
    "F": { path: 14.3, width: 4, height: 9.8 },
    "G": { path: 25.4, width: 7.9, height: 10.1 },
    "H": { path: 24.1, width: 7.6, height: 9.8 },
    "I": { path: 8.8, width: 1, height: 9.8 },
    "J": { path: 14.5, width: 5.2, height: 9.8 },
    "K": { path: 20.6, width: 6.3, height: 9.8 },
    "L": { path: 11.7, width: 3.9, height: 9.8 },
    "M": { path: 37.5, width: 9.6, height: 10 },
    "N": { path: 28.6, width: 7.6, height: 9.8 },
    "O": { path: 28.3, width: 10, height: 10 },
    "P": { path: 17.8, width: 4, height: 9.8 },
    "Q": { path: 32.2, width: 10, height: 10 },
    "R": { path: 21.8, width: 4.2, height: 9.8 },
    "S": { path: 48.2, width: 5.8, height: 9.8 },
    "T": { path: 13, width: 5.1, height: 9.8 },
    "U": { path: 20.6, width: 6, height: 9.8 },
    "V": { path: 19.5, width: 9.5, height: 9.8 },
    "W": { path: 38.3, width: 12.1, height: 9.8 },
    "X": { path: 19.6, width: 5.7, height: 9.8 },
    "Y": { path: 14.8, width: 6.2, height: 9.8 },
    "Z": { path: 18, width: 5.2, height: 9.8 },
    "1": { path: 10.1, width: 1, height: 9.8 },
    "2": { path: 24, width: 7.3, height: 9.8 },
    "3": { path: 22.4, width: 6.7, height: 9.8 },
    "4": { path: 22.6, width: 6.1, height: 9.8 },
    "5": { path: 24.6, width: 7.1, height: 9.8 },
    "6": { path: 22.7, width: 7.2, height: 9.8 },
    "7": { path: 15.2, width: 6.4, height: 9.8 },
    "8": { path: 28.5, width: 6.6, height: 9.8 },
    "9": { path: 23.7, width: 7.2, height: 9.8 },
    "0": { path: 28.2, width: 9.9, height: 9.8 },
    "!": { path: 8.4, width: 1, height: 9.8 },
    "@": { path: 46.6, width: 9.9, height: 10.8 },
    "#": { path: 33.5, width: 8.1, height: 9.8 },
    "$": { path: 30, width: 6.1, height: 9.8 },
    "%": { path: 34.6, width: 8.7, height: 9.8 },
    "&": { path: 28.9, width: 8.3, height: 10 },
    "*": { path: 12.8, width: 5.2, height: 4.8 },
    "(": { path: 11.6, width: 3.4, height: 11.2 },
    ")": { path: 11.6, width: 3.4, height: 11.2 },
    ",": { path: 3, width: 1.4, height: 3 },
    ".": { path: 1.2, width: 1, height: 2.4 },
    "/": { path: 10.9, width: 7.5, height: 9.8 },
    "<": { path: 8.1, width: 3.5, height: 4.2 },
    ">": { path: 8.1, width: 3.5, height: 4.2 },
    "?": { path: 15.3, width: 6.5, height: 9.8 },
    ";": { path: 3.3, width: 1.5, height: 7.1 },
    ":": { path: 2.4, width: 1, height: 6.3 },
    "\"": { path: 5.7, width: 3.6, height: 3.4 },
    "{": { path: 12, width: 3, height: 11.4 },
    "}": { path: 12, width: 3, height: 11.4 },
    "[": { path: 12.6, width: 2.3, height: 11.6 },
    "]": { path: 12.6, width: 2.3, height: 11.6 },
    "|": { path: 8.8, width: 1, height: 9.8 },
    "\\": { path: 10.9, width: 7.4, height: 9.8 },
    "=": { path: 8.5, width: 5.3, height: 2.8 },
    "+": { path: 7.2, width: 4.5, height: 4.6 },
  },
  ReenieBeanie: {
    "a": { path: 18.3, width: 8.2, height: 5.2 },
    "b": { path: 20.9, width: 5.9, height: 12.3 },
    "c": { path: 9.8, width: 5.1, height: 5.7 },
    "d": { path: 22.1, width: 4.8, height: 11.4 },
    "e": { path: 12.8, width: 4.9, height: 6.2 },
    "f": { path: 21, width: 6.1, height: 13.3 },
    "g": { path: 27.5, width: 4.9, height: 11.5 },
    "h": { path: 17.5, width: 5.6, height: 10.5 },
    "i": { path: 4.3, width: 2.5, height: 7.4 },
    "j": { path: 11.5, width: 4.4, height: 11.3 },
    "k": { path: 17.8, width: 7.1, height: 8.8 },
    "l": { path: 9.4, width: 2.5, height: 10 },
    "m": { path: 22.6, width: 7.7, height: 5.9 },
    "n": { path: 13.4, width: 5, height: 5.2 },
    "o": { path: 8.8, width: 2.8, height: 4.3 },
    "p": { path: 20.9, width: 6.9, height: 10.2 },
    "q": { path: 16.3, width: 4.1, height: 8.2 },
    "r": { path: 6.3, width: 3.5, height: 4.4 },
    "s": { path: 10, width: 3.8, height: 5.2 },
    "t": { path: 15.3, width: 7.3, height: 8.6 },
    "u": { path: 10.9, width: 5.9, height: 4.3 },
    "v": { path: 8.5, width: 3.4, height: 5.3 },
    "w": { path: 16.2, width: 5.2, height: 6.2 },
    "x": { path: 13.3, width: 5, height: 7.2 },
    "y": { path: 14.2, width: 4.2, height: 11.9 },
    "z": { path: 14, width: 6.6, height: 5.1 },
    "A": { path: 27, width: 5.3, height: 12.8 },
    "B": { path: 39.7, width: 9.5, height: 12.2 },
    "C": { path: 15.9, width: 7.4, height: 8.6 },
    "D": { path: 43.9, width: 16.8, height: 10.5 },
    "E": { path: 21.2, width: 5.2, height: 9.7 },
    "F": { path: 17.3, width: 5.5, height: 10.2 },
    "G": { path: 25.3, width: 7, height: 11.6 },
    "H": { path: 22.8, width: 4.7, height: 10.4 },
    "I": { path: 21.4, width: 9, height: 9.3 },
    "J": { path: 19.8, width: 6.9, height: 11 },
    "K": { path: 20.3, width: 7.3, height: 10 },
    "L": { path: 14, width: 6, height: 10.3 },
    "M": { path: 30.6, width: 8, height: 12.6 },
    "N": { path: 30.2, width: 8.2, height: 12.4 },
    "O": { path: 19.1, width: 5.5, height: 8 },
    "P": { path: 21.1, width: 5.9, height: 11.1 },
    "Q": { path: 30.9, width: 7.6, height: 10.5 },
    "R": { path: 30.6, width: 9.8, height: 10.6 },
    "S": { path: 21.5, width: 7, height: 9.4 },
    "T": { path: 20.9, width: 11.5, height: 11 },
    "U": { path: 24.5, width: 6.9, height: 9.3 },
    "V": { path: 16.7, width: 4.9, height: 10.3 },
    "W": { path: 26.6, width: 9.2, height: 11.1 },
    "X": { path: 20.7, width: 8, height: 10.7 },
    "Y": { path: 20, width: 5.8, height: 14.5 },
    "Z": { path: 21.4, width: 9.6, height: 9 },
    "1": { path: 8.2, width: 3.4, height: 6.7 },
    "2": { path: 13.8, width: 4.4, height: 7 },
    "3": { path: 14, width: 4.4, height: 6.5 },
    "4": { path: 16.3, width: 5.1, height: 8.9 },
    "5": { path: 11.9, width: 5.8, height: 5.7 },
    "6": { path: 14.4, width: 3.2, height: 7.5 },
    "7": { path: 9.7, width: 4.6, height: 4.9 },
    "8": { path: 20.6, width: 6.4, height: 7.6 },
    "9": { path: 16.5, width: 5.2, height: 8.5 },
    "0": { path: 14.5, width: 4.9, height: 5.6 },
    "!": { path: 7.1, width: 2.5, height: 9.2 },
    "@": { path: 27.3, width: 8.2, height: 10.1 },
    "#": { path: 33.7, width: 10.3, height: 9 },
    "$": { path: 25.8, width: 5.9, height: 12.5 },
    "%": { path: 32.4, width: 9.2, height: 14.4 },
    "&": { path: 15.8, width: 4.2, height: 15.3 },
    "*": { path: 13.1, width: 5.2, height: 4.5 },
    "(": { path: 8.8, width: 2.5, height: 8.3 },
    ")": { path: 8.2, width: 3.4, height: 7.7 },
    ",": { path: 3.6, width: 1.9, height: 3.9 },
    ".": { path: 1.1, width: 0.9, height: 1.9 },
    "/": { path: 9.6, width: 4.2, height: 9.7 },
    "<": { path: 11.4, width: 7, height: 5.4 },
    ">": { path: 11.8, width: 6, height: 6.6 },
    "?": { path: 12.2, width: 4.9, height: 10.1 },
    ";": { path: 4.5, width: 2.6, height: 7.1 },
    ":": { path: 2.1, width: 1.4, height: 4.9 },
    "\"": { path: 4.5, width: 3, height: 3.1 },
    "{": { path: 23.8, width: 4.5, height: 11.9 },
    "}": { path: 23.7, width: 6.3, height: 12.1 },
    "[": { path: 12.9, width: 3.9, height: 9 },
    "]": { path: 14.5, width: 4.9, height: 9.2 },
    "|": { path: 19, width: 1.9, height: 19.8 },
    "\\": { path: 9.6, width: 4.2, height: 9.7 },
    "=": { path: 8.4, width: 5.3, height: 3.5 },
    "+": { path: 9.2, width: 4.3, height: 6.3 },
  },
  RockSalt: {
    "a": { path: 26.7, width: 9.9, height: 9.7 },
    "b": { path: 34.9, width: 10.8, height: 9.1 },
    "c": { path: 17.8, width: 8.9, height: 6.9 },
    "d": { path: 23.8, width: 8.5, height: 8 },
    "e": { path: 23.4, width: 7.6, height: 9 },
    "f": { path: 24.6, width: 10, height: 9 },
    "g": { path: 28.9, width: 11.3, height: 9.3 },
    "h": { path: 25.3, width: 8.9, height: 9.3 },
    "i": { path: 5.7, width: 1.7, height: 6 },
    "j": { path: 23.3, width: 8.7, height: 7.9 },
    "k": { path: 22.2, width: 8.7, height: 8.2 },
    "l": { path: 11.1, width: 5.8, height: 7.1 },
    "m": { path: 29.5, width: 11, height: 8.9 },
    "n": { path: 25.3, width: 7.8, height: 9.8 },
    "o": { path: 20.8, width: 7.4, height: 7.6 },
    "p": { path: 24, width: 9.2, height: 8.4 },
    "q": { path: 36.7, width: 11.4, height: 10.5 },
    "r": { path: 33.7, width: 15.4, height: 8.6 },
    "s": { path: 16.4, width: 6.3, height: 9 },
    "t": { path: 16.3, width: 10.5, height: 6.9 },
    "u": { path: 18.7, width: 7.3, height: 6.1 },
    "v": { path: 17.1, width: 6.5, height: 9.4 },
    "w": { path: 30.3, width: 9.7, height: 9.1 },
    "x": { path: 20.3, width: 8, height: 9.3 },
    "y": { path: 17.1, width: 6.4, height: 9.6 },
    "z": { path: 25.9, width: 14.7, height: 6.9 },
    "A": { path: 41, width: 11.4, height: 16.5 },
    "B": { path: 48.6, width: 12.3, height: 13 },
    "C": { path: 29.1, width: 12.1, height: 13.4 },
    "D": { path: 39.2, width: 11.9, height: 14.2 },
    "E": { path: 48.1, width: 12.3, height: 18.5 },
    "F": { path: 35.1, width: 12.8, height: 14.2 },
    "G": { path: 41.2, width: 16.6, height: 15.6 },
    "H": { path: 40, width: 11.2, height: 16.1 },
    "I": { path: 13, width: 2.4, height: 12.7 },
    "J": { path: 34.6, width: 11.4, height: 14.4 },
    "K": { path: 42.3, width: 17.1, height: 14.8 },
    "L": { path: 22.8, width: 10.9, height: 13.2 },
    "M": { path: 50.6, width: 11.6, height: 17 },
    "N": { path: 38.3, width: 9.4, height: 15.9 },
    "O": { path: 38.1, width: 11.5, height: 13.6 },
    "P": { path: 40.4, width: 12.6, height: 15.8 },
    "Q": { path: 59.5, width: 17.3, height: 14.8 },
    "R": { path: 51.1, width: 17.8, height: 14.4 },
    "S": { path: 29.6, width: 10, height: 14 },
    "T": { path: 24.1, width: 12.7, height: 13.8 },
    "U": { path: 38.3, width: 8.8, height: 15.3 },
    "V": { path: 31.8, width: 8.3, height: 17.7 },
    "W": { path: 44.9, width: 13, height: 14.6 },
    "X": { path: 31.7, width: 9.8, height: 18.3 },
    "Y": { path: 29.8, width: 7.9, height: 15.8 },
    "Z": { path: 39.5, width: 15.2, height: 12.5 },
    "1": { path: 13.9, width: 2.6, height: 14.4 },
    "2": { path: 33, width: 11.9, height: 15.5 },
    "3": { path: 31.5, width: 9.9, height: 14.4 },
    "4": { path: 28.8, width: 6.4, height: 16.7 },
    "5": { path: 33.7, width: 8.6, height: 16.1 },
    "6": { path: 37.6, width: 9.6, height: 14.9 },
    "7": { path: 32.7, width: 9.7, height: 14.9 },
    "8": { path: 37.7, width: 8.4, height: 14.1 },
    "9": { path: 32.7, width: 10.4, height: 12.7 },
    "0": { path: 38.9, width: 11, height: 13.4 },
    "!": { path: 10.9, width: 2.2, height: 14.7 },
    "@": { path: 61.5, width: 14.8, height: 15.6 },
    "#": { path: 46.4, width: 13.4, height: 11.4 },
    "$": { path: 38.3, width: 9.6, height: 19.4 },
    "%": { path: 48, width: 12.9, height: 12.7 },
    "&": { path: 52.1, width: 12.7, height: 15.4 },
    "*": { path: 22, width: 8.9, height: 8.4 },
    "(": { path: 14.3, width: 4.2, height: 13.2 },
    ")": { path: 14.1, width: 3.6, height: 13.5 },
    ",": { path: 4.6, width: 2.3, height: 5 },
    ".": { path: 2.2, width: 2.8, height: 1.4 },
    "/": { path: 13.4, width: 6.5, height: 12.9 },
    "<": { path: 17.5, width: 8.1, height: 11.2 },
    ">": { path: 16.6, width: 6.9, height: 12 },
    "?": { path: 23.8, width: 9, height: 14.2 },
    ";": { path: 7, width: 3.8, height: 11.8 },
    ":": { path: 2.3, width: 1.1, height: 7.9 },
    "\"": { path: 8.6, width: 5.2, height: 4.9 },
    "{": { path: 24.3, width: 7.3, height: 16.1 },
    "}": { path: 24, width: 6.8, height: 16.2 },
    "[": { path: 22, width: 6.1, height: 14.1 },
    "]": { path: 23.2, width: 6.1, height: 15.1 },
    "|": { path: 11.9, width: 1.7, height: 12.5 },
    "\\": { path: 13.3, width: 9.3, height: 10.5 },
    "=": { path: 21.3, width: 12.7, height: 5.2 },
    "+": { path: 15.3, width: 9.2, height: 7.7 },
  },
  Sacramento: {
    "a": { path: 12.5, width: 5, height: 3.5 },
    "b": { path: 27, width: 5.5, height: 9.5 },
    "c": { path: 9.5, width: 4.5, height: 4 },
    "d": { path: 24.5, width: 5.5, height: 8.5 },
    "e": { path: 10, width: 4, height: 3.5 },
    "f": { path: 32, width: 3.5, height: 15 },
    "g": { path: 26, width: 5, height: 10 },
    "h": { path: 26, width: 5.5, height: 9.5 },
    "i": { path: 8.5, width: 3.5, height: 6 },
    "j": { path: 20.5, width: 4.5, height: 11.5 },
    "k": { path: 21, width: 5, height: 9 },
    "l": { path: 20, width: 4, height: 9.5 },
    "m": { path: 19.5, width: 8.5, height: 3.5 },
    "n": { path: 13.5, width: 6.5, height: 3.5 },
    "o": { path: 12, width: 5, height: 3.5 },
    "p": { path: 31.5, width: 6.5, height: 10.5 },
    "q": { path: 25, width: 5, height: 10 },
    "r": { path: 11, width: 5, height: 4.5 },
    "s": { path: 11.5, width: 4, height: 4.5 },
    "t": { path: 24, width: 7.5, height: 7.5 },
    "u": { path: 12, width: 5, height: 3.5 },
    "v": { path: 10, width: 5, height: 3.5 },
    "w": { path: 16, width: 6.5, height: 3.5 },
    "x": { path: 14, width: 5.5, height: 4.5 },
    "y": { path: 23.5, width: 4.5, height: 9 },
    "z": { path: 23, width: 5.5, height: 9 },
    "A": { path: 40, width: 14, height: 9.5 },
    "B": { path: 52.5, width: 13.5, height: 10 },
    "C": { path: 33.5, width: 10, height: 9.5 },
    "D": { path: 43.5, width: 12, height: 9.5 },
    "E": { path: 32, width: 10, height: 9.5 },
    "F": { path: 45, width: 15.5, height: 9.5 },
    "G": { path: 48.5, width: 10.5, height: 14 },
    "H": { path: 48, width: 15.5, height: 10 },
    "I": { path: 28, width: 8.5, height: 10 },
    "J": { path: 38, width: 7, height: 14.5 },
    "K": { path: 46, width: 14.5, height: 9.5 },
    "L": { path: 31, width: 11.5, height: 9.5 },
    "M": { path: 50.5, width: 16.5, height: 10 },
    "N": { path: 41.5, width: 16, height: 10 },
    "O": { path: 36, width: 10, height: 9.5 },
    "P": { path: 37, width: 11, height: 10 },
    "Q": { path: 42, width: 12.5, height: 10.5 },
    "R": { path: 47.5, width: 13, height: 10 },
    "S": { path: 31, width: 11, height: 9.5 },
    "T": { path: 36, width: 14, height: 10 },
    "U": { path: 40.5, width: 13, height: 9.5 },
    "V": { path: 33.5, width: 13, height: 11 },
    "W": { path: 50, width: 16.5, height: 10.5 },
    "X": { path: 36, width: 12.5, height: 10 },
    "Y": { path: 50, width: 12.5, height: 14 },
    "Z": { path: 37.5, width: 11, height: 9.5 },
    "1": { path: 14.5, width: 3.5, height: 9.5 },
    "2": { path: 33.5, width: 11, height: 9.5 },
    "3": { path: 37.5, width: 7, height: 9.5 },
    "4": { path: 29, width: 10, height: 10 },
    "5": { path: 26.5, width: 8, height: 10 },
    "6": { path: 29.5, width: 8.5, height: 10 },
    "7": { path: 18.5, width: 8.5, height: 9 },
    "8": { path: 32.5, width: 8, height: 10 },
    "9": { path: 29.5, width: 8.5, height: 10 },
    "0": { path: 26.5, width: 9, height: 9.5 },
    "!": { path: 9, width: 0.8, height: 9.5 },
    "@": { path: 27, width: 7, height: 7 },
    "#": { path: 18.5, width: 4, height: 6 },
    "$": { path: 21.5, width: 6, height: 9 },
    "%": { path: 25.5, width: 7, height: 7 },
    "&": { path: 23.5, width: 8, height: 7 },
    "*": { path: 25.5, width: 8, height: 7 },
    "(": { path: 13, width: 4, height: 11 },
    ")": { path: 13, width: 4, height: 11 },
    ",": { path: 2.5, width: 1.4, height: 2.5 },
    ".": { path: 2, width: 0.8, height: 2 },
    "/": { path: 10.5, width: 6.5, height: 9.5 },
    "<": { path: 8, width: 4, height: 5 },
    ">": { path: 8, width: 4, height: 5 },
    "?": { path: 24.5, width: 9, height: 9.5 },
    ";": { path: 4, width: 1.5, height: 5 },
    ":": { path: 3.5, width: 0.8, height: 5 },
    "\"": { path: 5, width: 3, height: 2.5 },
    "{": { path: 15, width: 4, height: 11 },
    "}": { path: 15, width: 4, height: 11 },
    "[": { path: 15, width: 3, height: 12 },
    "]": { path: 15, width: 3, height: 12 },
    "|": { path: 10.5, width: 1, height: 10.5 },
    "\\": { path: 10.5, width: 6, height: 9.5 },
    "=": { path: 7, width: 4, height: 2.5 },
    "+": { path: 7.5, width: 4, height: 4 },
  },
  Satisfy: {
    "a": { path: 18.5, width: 6.5, height: 5.5 },
    "b": { path: 26, width: 6.5, height: 6 },
    "c": { path: 14, width: 5, height: 5.5 },
    "d": { path: 31, width: 7.5, height: 9 },
    "e": { path: 16, width: 6, height: 5.5 },
    "f": { path: 29, width: 6.5, height: 14.5 },
    "g": { path: 36.5, width: 7, height: 11 },
    "h": { path: 23, width: 7, height: 9 },
    "i": { path: 11.5, width: 4.5, height: 8.5 },
    "j": { path: 24, width: 7, height: 13.5 },
    "k": { path: 26.5, width: 6.5, height: 9 },
    "l": { path: 9, width: 3, height: 9 },
    "m": { path: 29.5, width: 10, height: 5 },
    "n": { path: 19.5, width: 7, height: 5 },
    "o": { path: 19.5, width: 7, height: 5 },
    "p": { path: 27.5, width: 8, height: 10 },
    "q": { path: 31.5, width: 6.5, height: 10.5 },
    "r": { path: 19, width: 7.5, height: 6.5 },
    "s": { path: 12.5, width: 3.5, height: 6 },
    "t": { path: 20, width: 6.5, height: 9 },
    "u": { path: 19.5, width: 7, height: 5 },
    "v": { path: 16.5, width: 6.5, height: 5.5 },
    "w": { path: 28, width: 9, height: 5.5 },
    "x": { path: 16, width: 6.5, height: 5.5 },
    "y": { path: 20, width: 4.5, height: 10 },
    "z": { path: 28, width: 7, height: 10.5 },
    "A": { path: 32, width: 7.5, height: 12 },
    "B": { path: 36.5, width: 7, height: 11.5 },
    "C": { path: 22.5, width: 6.5, height: 11.5 },
    "D": { path: 27, width: 7, height: 11.5 },
    "E": { path: 27.5, width: 7, height: 11.5 },
    "F": { path: 25, width: 6.5, height: 11.5 },
    "G": { path: 29, width: 7, height: 11.5 },
    "H": { path: 37, width: 7.5, height: 11.5 },
    "I": { path: 29.5, width: 7.5, height: 11.5 },
    "J": { path: 23.5, width: 8.5, height: 11.5 },
    "K": { path: 31.5, width: 6.5, height: 12.5 },
    "L": { path: 29, width: 8, height: 11.5 },
    "M": { path: 46.5, width: 10, height: 11.5 },
    "N": { path: 38.5, width: 8, height: 11.5 },
    "O": { path: 27.5, width: 6, height: 11.5 },
    "P": { path: 26, width: 7.5, height: 11.5 },
    "Q": { path: 31.5, width: 6.5, height: 11.5 },
    "R": { path: 36, width: 7.5, height: 12 },
    "S": { path: 22, width: 6.5, height: 11.5 },
    "T": { path: 18, width: 6.5, height: 11 },
    "U": { path: 27.5, width: 7, height: 11.5 },
    "V": { path: 23, width: 6.5, height: 11.5 },
    "W": { path: 45, width: 9.5, height: 11.5 },
    "X": { path: 33, width: 9, height: 11.5 },
    "Y": { path: 28.5, width: 6.5, height: 11 },
    "Z": { path: 21.5, width: 7, height: 11 },
    "1": { path: 11, width: 3, height: 9 },
    "2": { path: 19, width: 5.5, height: 9.5 },
    "3": { path: 20, width: 5.5, height: 9.5 },
    "4": { path: 20.5, width: 5.5, height: 9.5 },
    "5": { path: 20.5, width: 6, height: 9 },
    "6": { path: 23, width: 5, height: 9.5 },
    "7": { path: 14, width: 5, height: 9 },
    "8": { path: 23.5, width: 5.5, height: 9.5 },
    "9": { path: 24, width: 5, height: 9.5 },
    "0": { path: 20.5, width: 5, height: 9.5 },
    "!": { path: 9.5, width: 3, height: 9.5 },
    "@": { path: 39, width: 8, height: 8.5 },
    "#": { path: 24, width: 6, height: 6.5 },
    "$": { path: 16, width: 4.5, height: 8.5 },
    "%": { path: 30, width: 7, height: 9.5 },
    "&": { path: 27, width: 6, height: 10 },
    "*": { path: 10, width: 4.5, height: 4.5 },
    "(": { path: 12, width: 4, height: 11 },
    ")": { path: 12, width: 4, height: 11 },
    ",": { path: 3.5, width: 2, height: 3 },
    ".": { path: 2.2, width: 2, height: 1.6 },
    "/": { path: 11, width: 5.5, height: 9.5 },
    "<": { path: 7, width: 3.5, height: 5.5 },
    ">": { path: 7, width: 3.5, height: 5.5 },
    "?": { path: 16.5, width: 5.5, height: 10 },
    ";": { path: 5, width: 2, height: 5.5 },
    ":": { path: 4, width: 2, height: 4.5 },
    "\"": { path: 7, width: 3.5, height: 3 },
    "{": { path: 14, width: 4.5, height: 11.5 },
    "}": { path: 14, width: 4.5, height: 11.5 },
    "[": { path: 13.5, width: 4.5, height: 11.5 },
    "]": { path: 13.5, width: 4.5, height: 11.5 },
    "|": { path: 9.5, width: 3, height: 9.5 },
    "\\": { path: 9.5, width: 2.2, height: 9.5 },
    "=": { path: 8, width: 5, height: 2.5 },
    "+": { path: 8.5, width: 4.5, height: 4.5 },
  },
  ShadowsIntoLight: {
    "a": { path: 25.6, width: 6, height: 9.1 },
    "b": { path: 31.3, width: 6.5, height: 14.6 },
    "c": { path: 19.2, width: 5.8, height: 10.4 },
    "d": { path: 33.4, width: 4.8, height: 16.9 },
    "e": { path: 25, width: 7.5, height: 9.4 },
    "f": { path: 22.3, width: 5.4, height: 15.7 },
    "g": { path: 37.2, width: 6.5, height: 17 },
    "h": { path: 29.7, width: 7.1, height: 13 },
    "i": { path: 11, width: 1.9, height: 13.9 },
    "j": { path: 19.3, width: 4.8, height: 20.2 },
    "k": { path: 27.6, width: 6.6, height: 13.7 },
    "l": { path: 13.4, width: 1.4, height: 14.4 },
    "m": { path: 36.1, width: 8.9, height: 10.3 },
    "n": { path: 28.5, width: 7.3, height: 9.8 },
    "o": { path: 19.8, width: 4, height: 9.6 },
    "p": { path: 30.2, width: 7.5, height: 13.6 },
    "q": { path: 28.7, width: 7, height: 12.9 },
    "r": { path: 18.2, width: 6, height: 8.8 },
    "s": { path: 17.2, width: 5.7, height: 9.3 },
    "t": { path: 17.8, width: 5.4, height: 13.6 },
    "u": { path: 21.4, width: 5.6, height: 8.9 },
    "v": { path: 16, width: 5.3, height: 9.2 },
    "w": { path: 30.9, width: 8.7, height: 8.6 },
    "x": { path: 20.6, width: 7.3, height: 11.9 },
    "y": { path: 23, width: 5.6, height: 15.7 },
    "z": { path: 21.5, width: 10.5, height: 8.6 },
    "A": { path: 32.6, width: 8, height: 13.2 },
    "B": { path: 40.2, width: 8.5, height: 12.4 },
    "C": { path: 22.8, width: 7.6, height: 12.8 },
    "D": { path: 30.8, width: 8.9, height: 12.9 },
    "E": { path: 28.4, width: 7.1, height: 12.4 },
    "F": { path: 23.6, width: 6.8, height: 12.2 },
    "G": { path: 33.1, width: 9.5, height: 13.1 },
    "H": { path: 27, width: 6.3, height: 11.6 },
    "I": { path: 12, width: 1.2, height: 12.8 },
    "J": { path: 18.6, width: 5.9, height: 13.6 },
    "K": { path: 26.4, width: 8.5, height: 11.5 },
    "L": { path: 18.7, width: 8.1, height: 10.9 },
    "M": { path: 43.9, width: 10.7, height: 13.2 },
    "N": { path: 32.7, width: 8.1, height: 12.9 },
    "O": { path: 26.1, width: 6.1, height: 11.5 },
    "P": { path: 30.5, width: 8, height: 13.9 },
    "Q": { path: 39, width: 7.9, height: 13.2 },
    "R": { path: 36.8, width: 8, height: 13.6 },
    "S": { path: 25.2, width: 8.8, height: 12.3 },
    "T": { path: 22.8, width: 11.7, height: 12.6 },
    "U": { path: 31, width: 7, height: 12.5 },
    "V": { path: 22.2, width: 8.3, height: 12.3 },
    "W": { path: 43, width: 14.2, height: 13 },
    "X": { path: 23.8, width: 6.9, height: 12.1 },
    "Y": { path: 20.4, width: 7.9, height: 13.4 },
    "Z": { path: 31.6, width: 10.7, height: 11.8 },
    "1": { path: 10.1, width: 1, height: 10.9 },
    "2": { path: 19.8, width: 7.9, height: 9.8 },
    "3": { path: 24.2, width: 7.3, height: 11.7 },
    "4": { path: 29.2, width: 8.4, height: 13.3 },
    "5": { path: 23.3, width: 7, height: 11.7 },
    "6": { path: 28.9, width: 5.8, height: 14.1 },
    "7": { path: 18.7, width: 7.1, height: 12.8 },
    "8": { path: 28.9, width: 4.9, height: 12.1 },
    "9": { path: 28, width: 7.4, height: 11.9 },
    "0": { path: 20.6, width: 4.5, height: 9.7 },
    "!": { path: 9.6, width: 1.8, height: 15.3 },
    "@": { path: 68, width: 15.4, height: 15.8 },
    "#": { path: 27.9, width: 6.9, height: 9.5 },
    "$": { path: 33.7, width: 7.3, height: 15.2 },
    "%": { path: 29.4, width: 7.4, height: 13.6 },
    "&": { path: 18.5, width: 5.6, height: 7.5 },
    "*": { path: 28.5, width: 7.7, height: 8.3 },
    "(": { path: 15.6, width: 4.4, height: 14.5 },
    ")": { path: 12.6, width: 3.4, height: 12.4 },
    ",": { path: 2.6, width: 1, height: 3.4 },
    ".": { path: 1.5, width: 1.3, height: 2.1 },
    "/": { path: 13, width: 4.6, height: 13.2 },
    "<": { path: 12.6, width: 5.9, height: 8.5 },
    ">": { path: 13.1, width: 5.8, height: 9 },
    "?": { path: 19.9, width: 7, height: 16.3 },
    ";": { path: 4.6, width: 1.2, height: 7.9 },
    ":": { path: 3, width: 1.3, height: 6.6 },
    "\"": { path: 7.2, width: 2.6, height: 4.7 },
    "{": { path: 23.9, width: 5.8, height: 16.5 },
    "}": { path: 23.1, width: 5.6, height: 16.6 },
    "[": { path: 30.6, width: 7.1, height: 21.2 },
    "]": { path: 31, width: 8.2, height: 22.4 },
    "|": { path: 13.5, width: 1.4, height: 14.2 },
    "\\": { path: 18.4, width: 8.8, height: 17.4 },
    "=": { path: 10.7, width: 6.6, height: 4.6 },
    "+": { path: 13.5, width: 7.4, height: 7.4 },
  },
}

export const FONTICON_PATH_SIZE = [
  { path: 72, width: 17, height: 17 },
  { path: 101, width: 19, height: 16 },
  { path: 76, width: 18, height: 13 },
  { path: 134, width: 22, height: 15 },
  { path: 66, width: 16, height: 15 },
  { path: 122, width: 16, height: 16 },
  { path: 86, width: 18, height: 17 },
  { path: 70, width: 13, height: 19 },
  { path: 160, width: 31, height: 16 },
  { path: 52, width: 11, height: 11 },
  { path: 107, width: 19, height: 19 },
  { path: 37, width: 9, height: 7 },
  { path: 45, width: 9, height: 9 },
  { path: 40, width: 9, height: 9 },
  { path: 20, width: 11, height: 10 },
  { path: 70, width: 11, height: 15 },
  { path: 14, width: 14, height: 22 },
  { path: 140, width: 19, height: 18 },
  { path: 180, width: 29, height: 21 },
  { path: 40, width: 9, height: 7 },
  { path: 90, width: 15, height: 10 },
  { path: 80, width: 15, height: 10 },
  { path: 110, width: 12, height: 21 },
  { path: 140, width: 24, height: 31 },
  { path: 37, width: 9, height: 7 },
  { path: 32, width: 7, height: 7 },
  { path: 120, width: 15, height: 15 },
  { path: 193, width: 36, height: 34 },
  { path: 173, width: 25, height: 8 },
  { path: 174, width: 32, height: 31 },
  { path: 163, width: 15, height: 24 },
  { path: 554, width: 61, height: 47 },
  { path: 250, width: 32, height: 36 },
  { path: 57, width: 10, height: 15 },
  { path: 264, width: 24, height: 33 },
  { path: 90, width: 36, height: 4 },
  { path: 150, width: 16, height: 34 },
  { path: 150, width: 16, height: 34 },
  { path: 70, width: 20, height: 14 },
  { path: 100, width: 20, height: 14 },
  { path: 140, width: 20, height: 19 },
  { path: 75, width: 28, height: 18 },
  { path: 120, width: 30, height: 23 },
  { path: 85, width: 29, height: 19 },
]