import React from 'react';
import {
  connect
} from 'react-redux';
import LogInScreen from './auth/log-in';
import EditorScreen from './app/edtior';
import {
  bindActionCreators
} from 'redux';
import {
  actionTypes
} from '../reducer';

import * as firebase from 'firebase/app'
import 'firebase/firestore'

const config = {
  apiKey: "AIzaSyAf9ORSDyoTlww-CFdfI6y4T3P0gbCnvfc",
  authDomain: "amped-custom-neon.firebaseapp.com",
  databaseURL: "https://amped-custom-neon.firebaseio.com",
  projectId: "amped-custom-neon",
  storageBucket: "amped-custom-neon.appspot.com",
  messagingSenderId: "599405474901"
};
const firebaseApp = firebase.initializeApp(config);
const settings = {
  timestampsInSnapshots: true
};

export const firebaseDB = firebase.firestore(firebaseApp);
firebaseDB.settings(settings);

function App(props) {
  const {
    state
  } = props;
  // Get last ID from Firebase DB
  firebaseDB.collection('Status').doc('Order').onSnapshot(snapshot => {
    if (snapshot.exists) {
      const id = snapshot.data().lastId
      if (state.lastOrderId !== id) {
        props.setLastOrderId(id);
      }
    }
  })

  if (state.auth) {
    return <EditorScreen />
  }
  return <LogInScreen /> ;
}

const mapStateToProps = state => ({
  state
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLastOrderId: (id) => ({
    type: actionTypes.UPDATE_LAST_ORDER_ID,
    id,
  }),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);