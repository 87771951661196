import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import invert from 'invert-color';
import ColorPicker from './ColorPicker';
import { updateObject } from '../../utils/fabric';
import { actionTypes } from '../../reducer';
import { calc } from '../../utils/export';

function ShapeColorPicker(props) {
  const { state } = props;
  let colors;

  if (state.activeObject.type === 'group') {
    colors = state.activeObject._objects.map((object, index) => ({
      index: index,
      fill: object.stroke,
    }));
  } else {
    colors = [{
      index: 0,
      fill: state.activeObject.stroke,
    }];
  }

  function handleChange(uid, color) {
    if (state.activeObject.type === 'group') {
      state.activeObject._objects[uid].set('stroke', color);
      state.editor.renderAll();
    } else {
      updateObject(state, 'stroke', color);
    }
    calc();
    props.updateObject('stroke', color);
  }

  function toggleObjectHighlight(uid) {
    if (state.activeObject.type === 'group') {
      const color = state.activeObject._objects[uid].get('stroke');
      state.activeObject._objects[uid].set('stroke', invert(color));
    } else {
      const color = state.activeObject.get('stroke');
      state.activeObject.set('stroke', invert(color));
    }
    state.editor.renderAll();
  }

  return (
    <>
      {colors.map(color =>
        <ColorPicker
          key={`object-${color.index}`}
          color={color}
          onColorChange={handleChange}
          onMouseOver={toggleObjectHighlight}
          onMouseLeave={toggleObjectHighlight}
        />)}
    </>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateObject: (property, value) => ({
    type: actionTypes.UPDATE_OBJECT,
    property,
    value
  })
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShapeColorPicker);
