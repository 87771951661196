import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import CONFIRMICON from '../../assets/process_icon/confirm.svg';
import { actionTypes } from '../../reducer';

const styles = theme => ({
  root: {
    padding: '20px',
    fontSize: 30,
    height: '100%',
    overflowY: 'scroll',
  },
  subject: {
    fontSize: 25,
    fontWeight: 900,
    textTransform: 'uppercase',
    marginTop: 5,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  subjectIcon: {
    marginRight: 20,
    width: 40,
    height: 42,
  },
  textField: {
    margin: `0 ${theme.spacing.unit}px 10px`,
    width: '100%',
  },
  rowItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  smallTextField: {
    margin: `0 ${theme.spacing.unit}px 10px`,
    width: '26%',
  },
  agreeCheckButton: {
    marginTop: 20,
  }
});

function ProcessConfirm(props) {
  const { classes, state } = props;

  function handleChange(e) {
    props.updateForm(e.target.name, e.target.value);
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.subject}><img src={CONFIRMICON} className={classes.subjectIcon} alt='Review' /> Confirm</Typography>
      <form noValidate autoComplete="off">
        <TextField
          id="standard-company"
          label="Company"
          name="company"
          value={state.form.company}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-name"
          label="Name"
          name="name"
          value={state.form.name}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-email"
          label="Email"
          name="email"
          value={state.form.email}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-phone"
          label="Phone"
          name="phone"
          value={state.form.phone}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-address1"
          label="Shipping Address 1"
          name="shippingAddress1"
          value={state.form.shippingAddress1}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-address2"
          label="Shipping Address 2"
          name="shippingAddress2"
          value={state.form.shippingAddress2}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <div className={classes.rowItem}>
          <TextField
            id="standard-city"
            label="City"
            name="city"
            value={state.form.city}
            onChange={handleChange}
            className={classes.smallTextField}
            margin="normal"
          />
          <TextField
            id="standard-state"
            label="State"
            name="state"
            value={state.form.state}
            onChange={handleChange}
            className={classes.smallTextField}
            margin="normal"
          />
          <TextField
            id="standard-zip"
            label="ZIP"
            name="zip"
            value={state.form.zip}
            onChange={handleChange}
            className={classes.smallTextField}
            margin="normal"
          />
        </div>
        <TextField
          id="standard-credit"
          label="Credit Card"
          name="creditCard"
          value={state.form.creditCard}
          onChange={handleChange}
          className={classes.textField}
          margin="normal"
        />
        <div className={classes.rowItem}>
          <TextField
            id="standard-expiry"
            label="Expiry"
            name="expiry"
            value={state.form.expiry}
            onChange={handleChange}
            className={classes.smallTextField}
            margin="normal"
          />
          <TextField
            id="standard-cvc"
            label="CVC"
            name="cvc"
            value={state.form.cvc}
            onChange={handleChange}
            className={classes.smallTextField}
            margin="normal"
          />
        </div>
        {/* <div className={classes.agreeCheckButton}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
              />
            }
            label="I agree with terms and conditions"
          />
        </div> */}
        <br />
      </form>
    </div>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: (property, value) => ({
    type: actionTypes.UPDATE_FORM,
    property,
    value,
  }),
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProcessConfirm));
