import jsPDF from 'jspdf';
import { uniq, without } from 'ramda';
import {
  trimCanvas,
  round,
  getRealSize,
  getDataUri
} from './helper';
import {
  PGTForIndividual,
  PGTForMaster,
  individualPackagingT,
  TUBINGCOLORCOSTS,
  SIGNCOSTS,
  shippingBoxCostUnit,
  bgIconsList,
  backgroundStyle,
  colorNames,
  rateCmToPx,
  SCREEN_SCALE,
  rateCanvasToReal,
  FONT_INFO,
  DRIVECOSTS
} from '../constants/calculation';
import { getLength, getBigFontsInTexts } from './fabric';
import { store } from '..';
import { actionTypes } from '../reducer';
import {  FONTICON_PATH_SIZE } from '../constants/length';
import IMG_HEADER from '../assets/pdf/header.png';

export async function exportCanvasToPDF() {
  const state = store.getState();

  const {
    lettersPathLength,
    artWork,
    colorList,
    powerCord,
    bgType,
    driverType,
    dimensionalWeight,
    productTotalCost,
    packagingCost,
    individualPackagingW,
    individualPackagingH,
    masterPackagingT,
    masterPackagingW,
    masterPackagingH
  } = state;

  let doc, fy;

  const neonTextWidthCm = state.width;
  const neonTextHeightCm = state.height;

  if (neonTextWidthCm <= 0 || neonTextHeightCm <= 0) {
    return;
  }

  let imgData = await generatePDFImage();

  if (neonTextHeightCm <= neonTextWidthCm) {
    doc = new jsPDF('l', 'cm', [neonTextHeightCm + 12, neonTextWidthCm + 12]);
  } else {
    doc = new jsPDF('p', 'cm', [neonTextWidthCm + 12, neonTextHeightCm + 12]);
  }

  doc.rect(0, 0, neonTextWidthCm + 12, neonTextHeightCm + 12, 'F');
  doc.addImage(imgData, 'JPEG', 6, 6, neonTextWidthCm, neonTextHeightCm, '', 'FAST');
  doc.setDrawColor(255, 255, 255);
  doc.setLineWidth(0.1);
  doc.rect(3, 3, neonTextWidthCm + 6, neonTextHeightCm + 6, 'S');

  doc.addPage('a4', 'portrait');

  const headerImgData = await getDataUri(IMG_HEADER);
  doc.addImage(headerImgData, 'PNG', 0.25, 1.5, 20.59, 1.9);

  doc.setFontSize(25);
  fy = 5.5;
  doc.text("Material:", 2, fy);
  doc.text(bgIconsList[bgType].title, 10, fy);

  fy += 1.5;
  doc.text("Style:", 2, fy);
  doc.text(backgroundStyle, 10, fy);
  
  fy += 1.5;
  doc.text("Power cord:", 2, fy);
  doc.text(powerCord, 10, fy);


  let colorStr = "";
  let moveY = 0;
  fy += 1.5;
  doc.text("Tube colour:", 2, fy);

  colorList.forEach((color, n) => {
    if (colorStr === "") {
      colorStr += color;
    } else {
      colorStr += "," + color;
    }

    if (n !== 0 && n % 2 === 0 && n <= (colorList.length - 1)) {
      moveY += parseFloat((n / 2) * 1.5);
      colorStr += ","
      doc.text(colorStr, 10, fy - 1.5 + moveY);
      colorStr = "";
    }
  });

  doc.text(colorStr, 10, fy + moveY);
  fy += moveY;

  fy += 1.5;
  doc.text("Driver:", 2, fy);
  doc.text(driverType, 10, fy);

  const tubeLength = round(lettersPathLength * 100, 4) + "cm";
  fy += 1.5;
  doc.text("Tubing Length:", 2, fy);
  doc.text(tubeLength, 10, fy);

  fy += 1.5;
  doc.text("Dimensions (w x h):", 2, fy);
  doc.text(round(neonTextWidthCm + 6, 1).toFixed(1) + " x " + round(neonTextHeightCm + 6, 1).toFixed(1) + "cm", 10, fy);

  //------------------------------------
  fy += 1.5;
  doc.text("Inner box:", 2, fy);
  doc.text("12.0 x " + round(individualPackagingW, 1).toFixed(1) + " x " + round(individualPackagingH, 1).toFixed(1) + "cm", 10, fy);

  fy += 1.5;
  doc.text("Shipping box:", 2, fy);
  doc.text(round(masterPackagingT, 1).toFixed(1) + " x " + round(masterPackagingW, 1).toFixed(1) + " x " + round(masterPackagingH, 1).toFixed(1) + "cm", 10, fy);

  fy += 1.5;
  doc.text("Vol. weight:", 2, fy);
  doc.text(round(dimensionalWeight, 2) + "kg", 10, fy);

  //------------------------------------
  fy += 1.5;
  doc.text("Product cost:", 2, fy);
  doc.text("$" + round(artWork, 2), 10, fy);

  fy += 1.5;
  doc.text("Packaging cost:", 2, fy);
  doc.text("$" + round(packagingCost, 2), 10, fy);

  doc.setFontStyle('bold')
  fy += 1.5;
  doc.text("Total cost:", 2, fy);
  doc.text("$" + round(productTotalCost, 2), 10, fy);

  doc.save('neon-canvas.pdf');
}

export async function calc() {

  const state = store.getState();

  const activeObject = state.editor.getActiveObject();
  state.editor.discardActiveObject();
  state.innerBoundary.setOptions({
    width: 0,
    height: 0,
    opacity: 0,
  });
  state.innerBoundary.setCoords();
  state.editor.renderAll();

  var ctx = state.editor.getContext('2d'),
    w = (~~state.editor.width) * SCREEN_SCALE,
    h = (~~state.editor.height) * SCREEN_SCALE,
    pixels = ctx.getImageData(0, 0, w, h),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x, y;

  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % w;
      y = ((i / 4) - x) / w;

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  bound.top /= SCREEN_SCALE;
  bound.left /= SCREEN_SCALE;
  bound.right /= SCREEN_SCALE;
  bound.bottom /= SCREEN_SCALE;

  const trimHeight = bound.bottom - bound.top;
  const trimWidth = bound.right - bound.left;

  if (trimWidth <= 0 || trimHeight <= 0) {
    return;
  }

  state.innerBoundary.setOptions({
    opacity: 1,
    ...bound,
    width: trimWidth,
    height: trimHeight,
    scaleX: 1,
    scaleY: 1,
  });
  state.innerBoundary.setCoords();

  //------------------------------------

  let neonTextWidthCm;
  let neonTextHeightCm;
  let colorList = [];
  let lettersPathLength = 0;
  let lettersPathLengthWithVariance = 0;

  const objects = state.editor.getObjects();

  await new Promise((resolve, reject) => {
    objects.forEach(async (object, index) => {
      if (object.type === 'i-text') {
        colorList.push(colorNames[object.get('fill').toLowerCase()]);
        const pathLength = getLength(object);
        lettersPathLength += pathLength.origin / 100;
        lettersPathLengthWithVariance += pathLength.variance / 100;
      } else {
        if (object.type === 'group') {
          object._objects.forEach(obj => colorList.push(colorNames[obj.get('stroke').toLowerCase()]));
        } else {
          colorList.push(colorNames[object.get('stroke').toLowerCase()]);
        }
        if (typeof object.iconIndex === 'number') {
          const realSize = await getRealSize(object);
          lettersPathLength += FONTICON_PATH_SIZE[object.iconIndex].path * (realSize.width / FONTICON_PATH_SIZE[object.iconIndex].width) / 100 / 4;
          lettersPathLengthWithVariance += FONTICON_PATH_SIZE[object.iconIndex].path * (realSize.width / FONTICON_PATH_SIZE[object.iconIndex].width) / 100 / 4;
        }
      }
      if (index === objects.length - 1) {
        resolve();
      }
    });
  })

  lettersPathLengthWithVariance = round(lettersPathLengthWithVariance, 4);

  colorList = uniq(colorList);
  colorList = without([undefined], colorList);

  neonTextWidthCm = trimWidth / 4;
  neonTextHeightCm = trimHeight / 4;

  let driverType = "";
  if (lettersPathLengthWithVariance < 2) {
    driverType = "3kv";
  } else if (lettersPathLengthWithVariance >= 2 && lettersPathLengthWithVariance < 3) {
    driverType = "4kv";
  } else if (lettersPathLengthWithVariance >= 3 && lettersPathLengthWithVariance < 4.5) {
    driverType = "6kv";
  } else if (lettersPathLengthWithVariance >= 4.5 && lettersPathLengthWithVariance < 6.5) {
    driverType = "8kv";
  } else if (lettersPathLengthWithVariance >= 6.5) {
    driverType = "10kv";
  }

  const signAreaWidth = round(neonTextWidthCm + 6, 1);
  const signAreaHeight = round(neonTextHeightCm + 6, 1);

  const individualPackagingW = signAreaWidth + PGTForIndividual * 2;
  const individualPackagingH = signAreaHeight + PGTForIndividual * 2;

  const masterPackagingW = individualPackagingW + PGTForMaster * 2;
  const masterPackagingH = individualPackagingH + PGTForMaster * 2;
  const masterPackagingT = individualPackagingT + PGTForMaster * 2;

  const signAreaSize = signAreaWidth * signAreaHeight / 10000;
  const signBgThickType = signAreaSize > 0.5 ? 'ten' : 'five';

  const dimensionalWeight = masterPackagingW * masterPackagingH * masterPackagingT / 5000;

  const packagingCost = round(signAreaSize * shippingBoxCostUnit, 2);
  const artWork = TUBINGCOLORCOSTS.white * lettersPathLengthWithVariance + DRIVECOSTS[driverType] + packagingCost;
  const mounting = round(SIGNCOSTS[signBgThickType][state.bgType] * signAreaSize, 2);
  const productTotalCost = round(artWork + mounting, 2);

  if (activeObject) {
    state.editor.setActiveObject(activeObject);
    state.editor.renderAll();
  }

  store.dispatch({
    type: actionTypes.UPDATE_CALCULATION,
    calc: {
      width: neonTextWidthCm,
      height: neonTextHeightCm,
      signAreaWidth,
      signAreaHeight,
      individualPackagingW,
      individualPackagingH,
      masterPackagingT,
      masterPackagingW,
      masterPackagingH,
      dimensionalWeight,
      packagingCost,
      artWork,
      mounting,
      productTotalCost,
      driverType,
      colorList,
      lettersPathLength,
      lettersPathLengthWithVariance
    }
  });
}

export async function exportFactoryPDF() {
  const state = store.getState();

  const {
    form,
    completeOrderId,
    signAreaWidth,
    signAreaHeight,
    individualPackagingW,
    individualPackagingH,
    masterPackagingT,
    masterPackagingW,
    masterPackagingH,
    packagingCost,
    artWork,
    productTotalCost,
    driverType,
    colorList,
    lettersPathLength,
    lettersPathLengthWithVariance,
    powerCord
  } = state;

  const neonTextWidthCm = state.width;
  const neonTextHeightCm = state.height;
  if (neonTextWidthCm <= 0 || neonTextHeightCm <= 0) {
    return;
  }

  let orderedDoc;
  let fy = 3.5;

  orderedDoc = new jsPDF('portrait', 'cm', 'a4');

  const headerImgData = await getDataUri(IMG_HEADER);
  orderedDoc.addImage(headerImgData, 'PNG', 0.25, 1.5, 20.59, 1.9);

  fy += 1;
  orderedDoc.setFontSize(14);
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Company: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.company, 7, fy);

  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`ORDER: `, 13, fy);
  orderedDoc.setFontStyle('normal');
  let orderNumber = ''
  if (completeOrderId < 10) orderNumber = '000' + completeOrderId
  else if (completeOrderId >= 10 && completeOrderId < 100) orderNumber = '00' + completeOrderId
  else if (completeOrderId >= 100 && completeOrderId < 1000) orderNumber = '0' + completeOrderId
  else orderNumber = '' + completeOrderId
  orderedDoc.text(`CUSTOM-${orderNumber}`, 16, fy);

  fy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Contact: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.name, 7, fy);

  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`DATE: `, 13, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(new Date().toLocaleDateString('en-US', { year: 'numeric', 'month': 'short', day: 'numeric' }), 16, fy);

  fy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Email: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.email, 7, fy);

  fy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Phone: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.phone, 7, fy);

  fy += 2.0;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Ship To: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.shippingAddress1, 7, fy);

  fy += 0.7;
  orderedDoc.text(form.shippingAddress2, 7, fy);

  fy += 0.7;
  orderedDoc.text(`${form.city}, ${form.state}, ${form.zip}`, 7, fy);


  fy += 2.0;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Production Details `, 1.5, fy);

  orderedDoc.setLineWidth(0.03);
  orderedDoc.rect(1.42, fy + 0.4, 4, 0.7 * 10 , 'S');
  orderedDoc.rect(5.42, fy + 0.4, 14.0, 0.7 * 10 , 'S');

  fy += 1;
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(`Size: `, 1.5, fy);
  orderedDoc.text(signAreaWidth + " x " + signAreaHeight + "cm", 7, fy);

  fy += 0.7;
  orderedDoc.text(`Tube colour: `, 1.5, fy);
  orderedDoc.text(colorList.join(', '), 7, fy);

  fy += 0.7;
  orderedDoc.text(`Mounting Style: `, 1.5, fy);
  orderedDoc.text(bgIconsList[state.bgType].title, 7, fy);

  fy += 0.7;
  orderedDoc.text(`Power cord: `, 1.5, fy);
  orderedDoc.text(powerCord, 7, fy);

  fy += 0.7;
  orderedDoc.text(`Letter Length: `, 1.5, fy);
  orderedDoc.text(round(lettersPathLength * 100, 4) + "cm", 7, fy);

  fy += 0.7;
  orderedDoc.text(`Total Length: `, 1.5, fy);
  orderedDoc.text(round(lettersPathLengthWithVariance * 100, 4) + "cm", 7, fy);

  fy += 0.7;
  orderedDoc.text(`Driver: `, 1.5, fy);
  orderedDoc.text(driverType, 7, fy);

  fy += 0.7;
  orderedDoc.text(`Inner Box: `, 1.5, fy);
  orderedDoc.text(`${individualPackagingT} x ${individualPackagingW} x ${individualPackagingH} cm`, 7, fy);

  fy += 0.7;
  orderedDoc.text(`Shipping Box: `, 1.5, fy);
  orderedDoc.text(`${masterPackagingT} x ${masterPackagingW} x ${masterPackagingH} cm`, 7, fy);


  fy += 2.0;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Product Cost: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text("$" + round(artWork, 2), 7, fy);

  fy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Packaging Cost: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text("$" + round(packagingCost, 2), 7, fy);

  fy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Total: `, 1.5, fy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text("$" + round(productTotalCost, 2), 7, fy);

  // Screen Shot
  if (neonTextHeightCm <= neonTextWidthCm) {
    orderedDoc.addPage([neonTextHeightCm + 12, neonTextWidthCm + 12], 'l');
  } else {
    orderedDoc.addPage([neonTextWidthCm + 12, neonTextHeightCm + 12], 'p');
  }

  const imgData = await generatePDFImage();
  orderedDoc.rect(0, 0, neonTextWidthCm + 12, neonTextHeightCm + 12, 'F');
  orderedDoc.addImage(imgData, 'JPEG', 6, 6, neonTextWidthCm, neonTextHeightCm, '', 'FAST');
  orderedDoc.setDrawColor(255, 255, 255);
  orderedDoc.setLineWidth(0.1);
  orderedDoc.rect(3, 3, neonTextWidthCm + 6, neonTextHeightCm + 6, 'S');

  orderedDoc.save(`${completeOrderId}-factory.pdf`);
}

export async function exportCustomerPDF() {
  const state = store.getState();

  const {
    form,
    completeOrderId,
    signAreaWidth,
    signAreaHeight,
    productTotalCost,
    colorList,
    powerCord
  } = state;

  const neonTextWidthCm = state.width;
  const neonTextHeightCm = state.height;
  if (neonTextWidthCm <= 0 || neonTextHeightCm <= 0) {
    return;
  }

  let orderedDoc;
  let cy = 3.5;

  orderedDoc = new jsPDF('portrait', 'cm', 'a4');

  const headerImgData = await getDataUri(IMG_HEADER);
  orderedDoc.addImage(headerImgData, 'PNG', 0.25, 1.5, 20.59, 1.9);

  cy += 1;
  orderedDoc.setFontSize(14);
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Company: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.company, 7, cy);

  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`ORDER: `, 13, cy);
  orderedDoc.setFontStyle('normal');
  let orderNumber = ''
  if (completeOrderId < 10) orderNumber = '000' + completeOrderId
  else if (completeOrderId >= 10 && completeOrderId < 100) orderNumber = '00' + completeOrderId
  else if (completeOrderId >= 100 && completeOrderId < 1000) orderNumber = '0' + completeOrderId
  else orderNumber = '' + completeOrderId
  orderedDoc.text(`CUSTOM-${orderNumber}`, 16, cy);

  cy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Contact: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.name, 7, cy);

  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`DATE: `, 13, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(new Date().toLocaleDateString('en-US', { year: 'numeric', 'month': 'short', day: 'numeric' }), 16, cy);

  cy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Email: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.email, 7, cy);

  cy += 0.7;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Phone: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.phone, 7, cy);


  cy += 1.5;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Ship To: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(form.shippingAddress1, 7, cy);

  cy += 0.7;
  orderedDoc.text(form.shippingAddress2, 7, cy);

  cy += 0.7;
  orderedDoc.text(`${form.city}, ${form.state}, ${form.zip}`, 7, cy);


  cy += 2.0;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`Production Details `, 1.5, cy);

  orderedDoc.setLineWidth(0.03);
  orderedDoc.rect(1.42, cy + 0.4, 4, 0.7 * 6 - 0.3 , 'S');
  orderedDoc.rect(5.42, cy + 0.4, 14.0, 0.7 * 6 - 0.3 , 'S');

  cy += 1;
  orderedDoc.setFontStyle('normal');
  orderedDoc.text(`Size: `, 1.5, cy);
  orderedDoc.text(signAreaWidth + " x " + signAreaHeight + "cm", 7, cy);

  cy += 0.7;
  orderedDoc.text(`Tube colour: `, 1.5, cy);
  orderedDoc.text(colorList.join(', '), 7, cy);

  cy += 0.7;
  orderedDoc.text(`Mounting Style: `, 1.5, cy);
  orderedDoc.text(bgIconsList[state.bgType].title, 7, cy);

  cy += 0.7;
  orderedDoc.text(`Power cord: `, 1.5, cy);
  orderedDoc.text(powerCord, 7, cy);

  cy += 0.7;
  orderedDoc.text(`Design: `, 1.5, cy);
  orderedDoc.text("See following page.", 7, cy);


  cy += 1.5;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`TOTAL*: `, 1.5, cy);
  orderedDoc.setFontStyle('normal');
  orderedDoc.text('$' + round(productTotalCost * 2 + 100, 2), 7, cy);


  cy += 2.0;
  orderedDoc.setFontStyle('bold');
  orderedDoc.text(`NOTE: `, 1.5, cy);

  orderedDoc.rect(1.42, cy + 0.5, 18, 0.7 * 13 - 0.5 , 'S');

  cy += 1.2;
  orderedDoc.setFontSize(11);
  orderedDoc.setFontStyle('normal');
  let note = `You are part of an early test group for our new neon platform that will change the game for custom`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `neon production.`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.7;
  note = `Your design has been submitted for review. You will receive an email confirmation with an update on`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `timing and costs within a few weeks.`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.7;
  note = `Please note that since neon tubing is a uniform thickness (approximatly 8mm), variations in`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `font thickness will not be achievable, however your sign will retain the overal shape and design of`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `the font.`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.7;
  note = `While we have done our best to accurately calculate costs, please note this is a ballpark figure`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `and excludes any shipping or taxes. The final cost will be provided in an invoice within the next`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `few weeks. Your credit card will not be charged until we have your approval.`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.7;
  note = `As this is a beta release please be advised there are many kinks that need to be worked out. We appr-`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `eciate your patience and any feedback you may have during this time.`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.7;
  note = `If you have any questions, please email custom@ampedandco.com and reference the order number`;
  orderedDoc.text(note, 1.6, cy);

  cy += 0.5;
  note = `located in the top right corner of this work order.`;
  orderedDoc.text(note, 1.6, cy);

  // Canvas screen shot
  const imgData = await generatePDFImage();
  if (neonTextHeightCm <= neonTextWidthCm) {
    orderedDoc.addPage([neonTextHeightCm + 12, neonTextWidthCm + 12], 'l');
  } else {
    orderedDoc.addPage([neonTextWidthCm + 12, neonTextHeightCm + 12], 'p');
  }

  orderedDoc.rect(0, 0, neonTextWidthCm + 12, neonTextHeightCm + 12, 'F');
  orderedDoc.addImage(imgData, 'JPEG', 6, 6, neonTextWidthCm, neonTextHeightCm, '', 'FAST');
  orderedDoc.setDrawColor(255, 255, 255);
  orderedDoc.setLineWidth(0.1);
  orderedDoc.rect(3, 3, neonTextWidthCm + 6, neonTextHeightCm + 6, 'S');

  orderedDoc.save(`${completeOrderId}-customer.pdf`);
}

function generatePDFImage() {
  return new Promise((resolve, reject) => {
    const state = store.getState();

    state.editor.discardActiveObject();
    state.innerBoundary.setOptions({
      width: 0,
      height: 0
    });
    state.innerBoundary.setCoords();
    state.editor.renderAll();
    // This will be needed to avoid the crop issue in a special font such as 'Allura'
    var extensionW = getBigFontsInTexts(state) / 2;
    var previewW = ~~((state.width + extensionW) * rateCmToPx);
    var previewH = ~~((state.height + extensionW) * rateCmToPx);

    window.downloadCanvas.setWidth(previewW);
    window.downloadCanvas.setHeight(previewH);

    var canvasJSONData = state.editor.toDatalessJSON();
    window.downloadCanvas.clear();
    window.downloadCanvas.loadFromDatalessJSON(canvasJSONData, function () {
      window.downloadCanvas.setZoom(rateCanvasToReal);
      var selection = new window.fabric.ActiveSelection(window.downloadCanvas.getObjects(), {
        canvas: window.downloadCanvas,
      });
      window.downloadCanvas.viewportCenterObject(selection);
      window.downloadCanvas.renderAll();

      //Creating a preview image
      let trimCanvasObj = trimCanvas(window.downloadCanvas, 8, "canvas");
      const imgData = trimCanvasObj.toDataURL('image/jpeg');
      resolve(imgData);
    });
  });
}
