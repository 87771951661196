import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColorPicker from './ColorPicker';
import { actionTypes } from '../../reducer';
import { updateObject } from '../../utils/fabric';
import { debounce } from '../../utils/helper';
import { calc } from '../../utils/export';

function TextColorPicker(props) {
  const { state } = props;

  function handleColorChange(uid, color) {
    updateObject(state, 'fill', color);
    props.updateObject('fill', color);

    debounce(calc(), 200);
  }

  return (
    <ColorPicker
      color={{
        index: state.activeObject.__uid,
        fill: state.activeObject.fill,
      }}
      onColorChange={handleColorChange} />
  )
}


const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateObject: (property, value) => ({
    type: actionTypes.UPDATE_OBJECT,
    property,
    value
  })
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextColorPicker);
