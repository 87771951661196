import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BrushIcon from '@material-ui/icons/Brush';
import SettingsIcon from '@material-ui/icons/Settings';

import TabDesign from './TabDesign';
import TabConfigure from './TabConfigure';
import { openedDrawerWidth } from '../theme';

const styles = theme => ({
  fullHeihgt: {
    height: '100%',
  },
  tabsRoot: {
    position: 'fixed',
    background: 'white',
    zIndex: 9,
    borderBottom: 'none',
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: openedDrawerWidth / 2,
    minHeight: 64,
    fontWeight: theme.typography.fontWeightRegular,
    paddingTop: 0,
    '&:hover': {
      color: 'white',
      background: '#6c6c6c',
    },
    '&$tabSelected': {
      color: 'white',
      background: '#6c6c6c',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#6c6c6c',
    },
  },
  tabSelected: {},
});

function ProcessDesign(props) {
  const { classes } = props;
  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event, value) => {
    setTabIndex(value);
  }

  return (
    <div className={classes.fullHeihgt}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <Tab
          label='DESIGN'
          icon={<BrushIcon />}
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label='CONFIGURE'
          icon={<SettingsIcon />}
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>
      {tabIndex === 0 && <TabDesign />}
      {tabIndex === 1 && <TabConfigure />}
    </div>
  )
}

export default withStyles(styles)(ProcessDesign)
