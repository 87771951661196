import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';

import InfoBar from './InfoBar';
import ConfBar from './ConfBar';

import IMG_BACKGROUND_1 from '../../assets/background/background-1.png'
import IMG_BACKGROUND_1_FS from '../../assets/background/background-1_fs.png'
import IMG_BACKGROUND_2 from '../../assets/background/background-2.jpg'
import IMG_BACKGROUND_3 from '../../assets/background/background-3.jpg'
import { actionTypes } from '../../reducer';
import { FONT_INFO } from '../../constants/calculation';
import { calc } from '../../utils/export';
import { hideBoundary } from '../../utils/fabric';
import { debounce } from '../../utils/helper';
import { setTimeout } from 'timers';
import { FONTICON_PATH_SIZE } from '../../constants/length';

const styles = {
  wrapper: {
    position: 'relative',
    background: '#ebebeb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  paper: {
    position: 'relative',
    width: 1036,
    height: 740,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1036,
    height: 740,
    objectFit: 'cover',
    zIndex: 0,
  },
  canvasContainer: {
    position: 'relative',
    width: 480,
    height: 480,
    marginTop: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '2px dashed rgba(255,255,255,0.9)',
    boxSizing: 'content-box',
    overflow: 'hidden'
  },
  canvasContainerFullscreen: {
    width: 700,
    height: 700,
    margin: '20px auto',
  }
}

const BACKGROUND = [
  IMG_BACKGROUND_1,
  IMG_BACKGROUND_2,
  IMG_BACKGROUND_3,
];

function Editor(props) {
  const { classes, state, setEditor } = props;

  useEffect(function () {
    if (window.fabric && !state.editor) {
      const downloadCanvas = document.createElement('canvas');
      downloadCanvas.setAttribute('id', 'downloadCanvas');
      window.downloadCanvas = new window.fabric.Canvas('downloadCanvas');
      const editor = new window.fabric.Canvas('custom-neon-editor');
      const innerBoundary = new window.fabric.Rect({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        fill: 'transparent',
        stroke: 'white',
        selectable: false,
      });
      editor.add(innerBoundary);

      editor.on('object:modified', handleObjectModified);
      editor.on('object:added', calculateBoundary);
      editor.on('object:removed', calculateBoundary);
      editor.on('object:moving', hideBoundary);
      editor.on('object:scaling', hideBoundary);
      editor.on('object:rotating', hideBoundary);
      editor.on('object:skewing', hideBoundary);
      editor.on('object:adding', hideBoundary);
      editor.on('object:removing', hideBoundary);
      editor.on('selection:cleared', handleSelectionCleared);
      editor.on('selection:created', handleSelectionCreated);
      editor.on('selection:updated', handleSelectionCreated);

      setEditor(editor, innerBoundary);
    }
  }, [state]);

  function calculateBoundary() {
    setTimeout(() => {
      debounce(calc(), 200);
    }, 0);
  }

  function handleObjectModified(e) {
    if (e.target.type === 'i-text') {
      if (e.target.scaleX !== 1) {
        e.target.fontSize *= e.target.scaleX;
        if (e.target.fontSize < FONT_INFO[e.target.fontFamily].fontsize) {
          e.target.fontSize = FONT_INFO[e.target.fontFamily].fontsize;
        }
        e.target.scaleX = 1;
        e.target.scaleY = 1;
        e.target.setCoords();
        props.updateObject('fontSize', e.target.fontSize);
      }
    } else {
      if (typeof e.target.iconIndex === 'number') {
        console.log(e.target.scaleX , e.target.minScaleRate)
        if (e.target.scaleX < e.target.minScaleRate) {
          e.target.scaleX = e.target.minScaleRate;
          e.target.scaleY = e.target.minScaleRate;
        }
      }
      props.setInnerObject();
    }

    calculateBoundary();
  }

  function handleSelectionCreated(event) {
    props.selectObject();
  }

  function handleSelectionCleared() {
    props.deSelectObject();
  }

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>
        <img
          className={classes.background}
          src={state.isFullScreen ? IMG_BACKGROUND_1_FS : BACKGROUND[state.bgImage]}
          alt="Background"
        />
        <div className={classNames(classes.canvasContainer, {
          [classes.canvasContainerFullscreen]: state.isFullScreen,
        })}>
          <canvas
            id="custom-neon-editor"
            width={state.isFullScreen ? 700 : 480}
            height={state.isFullScreen ? 700 : 480}
          />
        </div>
        <ConfBar />
        <InfoBar width={state.width} height={state.height} />
      </Paper>
    </div >
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  setEditor: (editor, innerBoundary) => ({
    type: actionTypes.SET_EDITOR,
    editor,
    innerBoundary,
  }),
  hideBoundary: () => ({
    type: actionTypes.HIDE_BOUNDARY
  }),
  selectObject: () => ({
    type: actionTypes.SELECT_OBJECT
  }),
  deSelectObject: () => ({
    type: actionTypes.DESELECT_OBJECT
  }),
  setInnerObject: () => ({
    type: actionTypes.SET_INNER_OBJECT
  }),
  updateObject: (property, value) => ({
    type: actionTypes.UPDATE_OBJECT,
    property,
    value
  })
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Editor));
