import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Radio, withStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/Clear';


const pixelUnit = {
  cm: 1,
  in: 2.54,
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: -100,
    left: '50%',
    height: 100,
    transform: 'translateX(-50%)',
    color: '#707070',
  },
  sizeContainer: {
    margin: '0 10px',
    padding: '5px 7px',
    color: '#1c1c1c',
    border: '1px solid #707070',
    borderRadius: 4,
  }
}

function InfoBar(props) {
  const { classes, state } = props;
  const [unit, setUnit] = useState('cm');

  function handleChange(event) {
    setUnit(event.target.value);
  }


  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" color="inherit">Size</Typography>
      <div className={classes.sizeContainer}>{(state.width / pixelUnit[unit]).toFixed(1)}</div>
      <Icon />
      <div className={classes.sizeContainer}>{(state.height / pixelUnit[unit]).toFixed(1)}</div>
      <Radio checked={unit === 'cm'} onChange={handleChange} value="cm" color="primary" name="unit" />{'cm'}
      <Radio checked={unit === 'in'} onChange={handleChange} value="in" color="primary" name="unit" />{'in'}
    </div>
  )
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(InfoBar));
