import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormControl, OutlinedInput, withStyles, Select, MenuItem } from "@material-ui/core";
import { updateObject } from '../../utils/fabric';
import { fontsList } from '../../constants/fonts';

const styles = {
  fontSelect: {
    width: 280,
    margin: '0 10px',
  },
  fontSelectInput: {
    padding: '7px 10px'
  },
  selectOption: {
    color: '#43425d'
  },
}

function FontPicker(props) {
  const { classes, state } = props;

  function handleChange(event) {
    updateObject(state, 'fontFamily', event.target.value);
  }

  return (
    <FormControl variant="outlined" className={classes.fontSelect}>
      <Select
        color="#43425d"
        value={state.activeObject.fontFamily}
        onChange={handleChange}
        input={
          <OutlinedInput
            classes={{ input: classes.fontSelectInput }}
            value={state.activeObject.fontFamily || ''}
            name="font-family"
            labelWidth={0}
          />}
      >
        {fontsList.map(font =>
          <MenuItem
            key={font.name}
            className={
              classNames({
                [classes.selectOption]: state.activeObject.fontFamily === font.file
              })}
            value={font.file}
          >
            {font.name}
          </MenuItem>)}
      </Select>
    </FormControl>
  )
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(FontPicker));
