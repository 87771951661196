import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Popover, withStyles } from '@material-ui/core';

import ToolGroup from './ToolGroup';
import ToolButton from './ToolButton';

import { alignItems as fabricAlignItems } from '../../utils/fabric';
import * as images from '../../constants/images';


const styles = {
  popOver: {
    padding: '10px 0',
  }
}

function AlignItemsBar(props) {
  const { classes, state } = props;
  const [anchor, setAnchor] = useState(null);

  function handleOpenAlignments(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function alignItems(align) {
    return function () {
      fabricAlignItems(state, align);
    };
  }

  return (
    <ToolGroup>
      <ToolButton
        aria-owns={!!anchor ? 'align-objects-popover' : undefined}
        aria-haspopup
        isActive={!!anchor}
        activeImage={images.IC_ALIGNMENT_OVER}
        inActiveImage={images.IC_ALIGNMENT}
        onClick={handleOpenAlignments}
      />
      <Popover
        id="align-objects-popover"
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popOver}>
          <ToolGroup>
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_LEFT}
              inActiveImage={images.IC_ALIGN_LEFT}
              onClick={alignItems('left')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_CENTERH}
              inActiveImage={images.IC_ALIGN_CENTERH}
              onClick={alignItems('center_h')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_RIGHT}
              inActiveImage={images.IC_ALIGN_RIGHT}
              onClick={alignItems('right')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_TOP}
              inActiveImage={images.IC_ALIGN_TOP}
              onClick={alignItems('top')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_CENTERV}
              inActiveImage={images.IC_ALIGN_CENTERV}
              onClick={alignItems('center_v')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_BOTTOM}
              inActiveImage={images.IC_ALIGN_BOTTOM}
              onClick={alignItems('bottom')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_BETWEENH}
              inActiveImage={images.IC_ALIGN_BETWEENH}
              onClick={alignItems('between_h')} />
            <ToolButton
              isActive={false}
              activeImage={images.IC_ALIGN_BETWEENV}
              inActiveImage={images.IC_ALIGN_BETWEENV}
              onClick={alignItems('between_v')} />
          </ToolGroup>
        </div>
      </Popover>
    </ToolGroup>
  );
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(AlignItemsBar));
