import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import ResultBoard from './ResultBoard';
import { openedDrawerWidth, closedDrawerWidth } from '../theme';
import ProcessDesign from './ProcessDesign';
import ProcessReview from './ProcessReview';
import ProcessConfirm from './ProcessConfirm';
import ProcessCompleteOrder from './ProcessCompleteOrder'
import ThankYou from './ThankYou'

const styles = theme => ({
  drawer: {
    width: openedDrawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    position: 'relative',
    width: openedDrawerWidth,
    paddingTop: 64,
    borderLeft: 'none',
  },
  drawerClose: {
    width: closedDrawerWidth,
    paddingTop: 64,
  },
  tabWrapper: {
    position: 'absolute',
    top: 0,
    width: openedDrawerWidth,
    height: 'calc(100vh - 365px - 65px)',
  },
});

function MiniDrawer(props) {
  const { classes, isDrawerOpen } = props;
  const [step, setStep] = useState(0);

  function handleStepUpdate(step) {
    setStep(step);
  }

  return (
    <Drawer
      variant='permanent'
      anchor='right'
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
      open={isDrawerOpen}
    >
      {step === 0 && <Divider />}
      <div className={classes.tabWrapper}>
        {step === 0 && <ProcessDesign />}
        {step === 1 && <ProcessReview />}
        {step === 2 && <ProcessConfirm />}
        {step === 3 && <ProcessCompleteOrder />}
        {step === 4 && <ThankYou />}
      </div>
      <ResultBoard updateStep={handleStepUpdate} />
    </Drawer>
  );
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
