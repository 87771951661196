import _ from 'lodash';
import { LETTER_PATH_SIZE, FONTICON_PATH_SIZE } from '../constants/length';
import { FONT_INFO, rateCmToPx, SCREEN_SCALE } from '../constants/calculation';
import { trimCanvas } from './helper';
import { store } from '..';

const basicObject = {
  originX: 'center',
  originY: 'center',
  hasControls: true,
  hasBorders: true,
  hasRotatingPoint: true,
  cornerStyle: 'circle',
  lockUniScaling: true,
  fill: '#FFFFFF'
};

export function addNewObject(object) {
  const state = store.getState();
  const { editor, magnification } = state;
  const objectProperty = {
    ...basicObject,
    ...object.data.property,
  };

  editor.discardActiveObject().renderAll();

  switch (object.type) {
    case 'i-text':
      const newObject = new window.fabric.IText(object.data.context, objectProperty);
      newObject.scaleX = magnification;
      newObject.scaleY = magnification;

      editor.add(newObject);
      editor.centerObject(newObject);
      newObject.setCoords();
      editor.setActiveObject(newObject);
      editor.renderAll();
      break;
    case 'shape':
      window.fabric.loadSVGFromURL(object.data.context, (objects, options) => {
        const iconIndex = object.data.property.iconIndex;
        const width = FONTICON_PATH_SIZE[iconIndex].width * 4 * magnification;
        const height = FONTICON_PATH_SIZE[iconIndex].height * 4 * magnification;
        const shape = window.fabric.util.groupSVGElements(
          objects,
          { ...options, cornerStyle: 'circle' },
        );

        window.downloadCanvas.clear();
        window.downloadCanvas.setWidth(shape.width + 30);
        window.downloadCanvas.setHeight(shape.height + 30);
        window.downloadCanvas.add(shape);
        window.downloadCanvas.setZoom(1);
        window.downloadCanvas.centerObject(shape);
        window.downloadCanvas.discardActiveObject();
        window.downloadCanvas.renderAll();
        const realSize = trimCanvas(window.downloadCanvas);

        console.log(width, height, shape.width, realSize.width)

        const minScaleRate = width * SCREEN_SCALE / realSize.width;

        console.log(width / realSize.width)
        objects.forEach(object => object.setOptions({
          stroke: '#ffffff',
          scaleX: minScaleRate,
          scaleY: minScaleRate,
          left: object.left * minScaleRate,
          top: object.top * minScaleRate
        }));
        if (objects.length > 1) {
          shape.setOptions({
            iconIndex,
            width,
            height,
            scaleX: 1,
            scaleY: 1,
            originX: 'center',
            originY: 'center',
            minScaleRate
          })
        } else {
          shape.setOptions({
            iconIndex,
            minScaleRate
          });
        }

        console.log(shape.iconIndex, shape.minScaleRate)

        editor.add(shape);
        editor.centerObject(shape);
        shape.setCoords();
        editor.setActiveObject(shape);
        editor.renderAll();
      });
      break;
    default:
      break;
  }
  // getBoundingRect();
}

export function updateObject(state, property, value) {
  if (state.activeObject) {
    if (state.activeObject.type === 'activeSelection') {
      state.activeObject.forEachObject((obj) => {
        obj.set(property, value);
      });
    } else {
      state.activeObject.set(property, value);
    }
    state.editor.renderAll();
  }
}

export function alignItems(state, rule) {
  const { editor, activeObject } = state;
  if (activeObject.type !== 'activeSelection') { return; }

  const objects = activeObject.getObjects();

  const groupWidth = activeObject.getBoundingRect(true).width;
  const groupHeight = activeObject.getBoundingRect(true).height;
  let itemsWidth = 0;
  let itemsHeight = 0;

  objects.forEach((item) => {
    const itemWidth = item.getBoundingRect().width;
    const itemHeight = item.getBoundingRect().height;

    itemsWidth += itemWidth;
    itemsHeight += itemHeight;

    switch (rule) {
      case 'top':
        item.set({ top: (-groupHeight + itemHeight) / 2 });
        break;
      case 'right':
        item.set({ left: (groupWidth - itemWidth) / 2 });
        break;
      case 'bottom':
        item.set({ top: (groupHeight - itemHeight) / 2 });
        break;
      case 'left':
        item.set({ left: (-groupWidth + itemWidth) / 2 });
        break;
      case 'center_h':
        item.set({ left: 0 });
        break;
      case 'center_v':
        item.set({ top: 0 });
        break;
      default:
        break;
    }
  });

  if (rule === 'between_v') {
    const indexList = _.range(objects.length);
    for (let i = 0; i < objects.length - 1; i += 1) {
      for (let j = i + 1; j < objects.length; j += 1) {
        if (objects[indexList[i]].height > objects[indexList[j]].height) {
          const tempIndex = indexList[i];
          indexList[i] = indexList[j];
          indexList[j] = tempIndex;
        }
      }
    }
    let top = -groupHeight / 2;
    const space = (groupHeight - itemsHeight) / (objects.length - 1);
    indexList.forEach((index) => {
      top += objects[index].getBoundingRect().height / 2;
      objects[index].set({ top });
      top += objects[index].getBoundingRect().height / 2;
      top += space;
    });
  }
  if (rule === 'between_h') {
    const indexList = _.range(objects.length);
    for (let i = 0; i < objects.length - 1; i += 1) {
      for (let j = i + 1; j < objects.length; j += 1) {
        if (objects[indexList[i]].left > objects[indexList[j]].left) {
          const tempIndex = indexList[i];
          indexList[i] = indexList[j];
          indexList[j] = tempIndex;
        }
      }
    }
    let left = -groupWidth / 2;
    const space = (groupWidth - itemsWidth) / (objects.length - 1);
    indexList.forEach((index) => {
      left += objects[index].getBoundingRect().width / 2;
      objects[index].set({ left });
      left += objects[index].getBoundingRect().width / 2;
      left += space;
    });
  }
  activeObject.forEachObject((item) => {
    activeObject.removeWithUpdate(item).addWithUpdate(item);
  });
  activeObject.forEachObject((item) => {
    activeObject.removeWithUpdate(item).addWithUpdate(item);
  });
  activeObject.setCoords();
  editor.renderAll();
}

export function deleteObject() {
  const state = store.getState();
  const { editor } = state;
  const activeObject = editor.getActiveObject();
  if (activeObject) {
    editor.discardActiveObject();

    if (activeObject.type === 'activeSelection') {
      activeObject.forEachObject((obj) => {
        editor.remove(obj);
      });
    } else {
      editor.remove(activeObject);
    }
  }
}

export function duplicateObject() {
  const state = store.getState();
  const { editor } = state;
  const activeObject = editor.getActiveObject();
  // Check out if any object has been selected.

  return new Promise((resolve, reject) => {
    if (activeObject) {
      activeObject.clone((cloned) => {
        editor.discardActiveObject();
        cloned.set({
          top: cloned.top + 20,
          left: cloned.left + 20,
          evented: true,
        });
        if (cloned.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          cloned.set('canvas', editor);
          cloned.forEachObject((obj) => {
            editor.add(obj);
          });
          cloned.setCoords();
        } else {
          editor.add(cloned);
        }
        editor.setActiveObject(cloned);
        editor.renderAll();
        resolve(cloned);
      });
    }
    reject({ msg: 'No object selected' });
  });
}

export function hideBoundary() {
  const state = store.getState();
  if (state.isBoundaryAvailable) {
    state.innerBoundary.setOptions({
      width: 0,
      height: 0
    });
    state.innerBoundary.setCoords();
    state.editor.renderAll();
  }
}

export function getBoundingRect() {
  const state = store.getState();
  state.editor.renderAll();
  const boundaryGroup = new window.fabric.Group(state.editor.getObjects());
  boundaryGroup.removeWithUpdate(state.innerBoundary);
  const boundary = boundaryGroup.getBoundingRect(true);
  boundary.scaleX = 1;
  boundary.scaleY = 1;
  state.innerBoundary.setOptions(boundary);
  state.innerBoundary.setCoords();
  boundaryGroup.destroy();

  const trimmed = trimCanvas(state.editor);

  return {
    width: trimmed.width / state.magnification,
    height: trimmed.height / state.magnification,
  }
}

export function getLength(object) {
  let length = 0;

  if (!object || object.type !== 'i-text') {
    return length;
  }
  
  // Remove the back space in string
  let str = object.text.split(' ').join('');
  // Remove Enter in string
  str = str.replace(/(\r\n|\n|\r)/gm, "")
  const fontFamily = object.get('fontFamily');

  for (let i = 0; i < str.length; i++) {
    length += LETTER_PATH_SIZE[fontFamily][str.charAt(i)].path;
  }
  length *= object.fontSize / FONT_INFO[fontFamily].fontsize;

  return {
    origin: length,
    variance: length * FONT_INFO[fontFamily].variance,
  };
}

export function toggleFullScreen() {
  const state = store.getState();
  const magnification = state.isFullScreen ? 1 : 700 / 480;
  const canvasSize = state.isFullScreen
    ? {
      width: 480,
      height: 480,
    } : {
      width: 700,
      height: 700,
    };

  state.editor.setWidth(canvasSize.width);
  state.editor.setHeight(canvasSize.height);
  state.editor.setZoom(magnification);
}

export function getBigFontsInTexts() {
  const state = store.getState();
  let fontSize = 20;
  state.editor.getObjects().forEach(object => {
    if (object.type === 'i-text' && object.get('fontSize') > fontSize) {
      fontSize = object.get('fontSize');
    }
  })
  return fontSize;
}
