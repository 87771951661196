import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IMG_LOGO from '../../assets/logo.png';

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'white',
    color: 'black',
    borderBottom: '1px solid #707070',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  logoText: {
    marginLeft: 10,
    paddingTop: 15,
    height: 35,
    lineHeight: '20px',
  },
  menuButton: {
    marginLeft: 'auto',
  },
});

function Header(props) {
  const { classes, isDrawerOpen, toggleDrawer } = props;

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Toolbar>
        <img className={classes.logo} src={IMG_LOGO} alt="logo" />
        <Typography variant="h5" color="inherit" className={classes.logoText} noWrap>
          CUSTOM
        </Typography>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={toggleDrawer}
          className={classNames(classes.menuButton)}
        >
          {isDrawerOpen
            ? <ChevronRightIcon />
            : <MenuIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);
