export const fontsList = [
  {
    file: 'Allura',
    name: 'Allura',
  },
  {
    file: 'AmaticSC',
    name: 'Amatic',
  },
  {
    file: 'Caveat',
    name: 'Caveat',
  },
  {
    file: 'Charmonman',
    name: 'Charmonman',
  },
  {
    file: 'Comfortaa',
    name: 'Comfortaa',
  },
  {
    file: 'CutiveMono',
    name: 'Cutive',
  },
  {
    file: 'GiveYouGlory',
    name: 'Give You Glory',
  },
  {
    file: 'Gruppo',
    name: 'Gruppo',
  },
  {
    file: 'JuliusSansOne',
    name: 'Julius',
  },
  {
    file: 'MrDafoe',
    name: 'Mr Dafoe',
  },
  {
    file: 'Nunito',
    name: 'Nunito',
  },
  {
    file: 'PoiretOne',
    name: 'Poiret One',
  },
  {
    file: 'ReenieBeanie',
    name: 'Reenie Beanie',
  },
  {
    file: 'RockSalt',
    name: 'Rock Salt',
  },
  {
    file: 'Sacramento',
    name: 'Sacramento',
  },
  {
    file: 'Sanchez',
    name: 'Sanchez',
  },
  {
    file: 'Satisfy',
    name: 'Satisfy',
  },
  {
    file: 'ShadowsIntoLight',
    name: 'Shadows Into Light',
  },
]
