/**
 * @description Toolbar assets
 */
/** Text Align */
export const IC_TEXTALIGN_LEFT = require('../assets/toolbar_icons/ic-leftalign.svg');
export const IC_TEXTALIGN_LEFT_OVER = require('../assets/toolbar_icons/ic-leftalign-hover.svg');
export const IC_TEXTALIGN_CENTER = require('../assets/toolbar_icons/ic-centeralign.svg');
export const IC_TEXTALIGN_CENTER_OVER = require('../assets/toolbar_icons/ic-centeralign-hover.svg');
export const IC_TEXTALIGN_RIGHT = require('../assets/toolbar_icons/ic-rightalign.svg');
export const IC_TEXTALIGN_RIGHT_OVER = require('../assets/toolbar_icons/ic-rightalign-hover.svg');

/** Align Items  */
export const IC_ALIGNMENT = require('../assets/toolbar_icons/ic-alignment.svg');
export const IC_ALIGNMENT_OVER = require('../assets/toolbar_icons/ic-alignment-hover.svg');
export const IC_ALIGN_CENTERV = require('../assets/toolbar_icons/ic-aligntocenterv.svg');
export const IC_ALIGN_CENTERH = require('../assets/toolbar_icons/ic-aligntocenterh.svg');
export const IC_ALIGN_TOP = require('../assets/toolbar_icons/ic-aligntotop.svg');
export const IC_ALIGN_RIGHT = require('../assets/toolbar_icons/ic-aligntoright.svg');
export const IC_ALIGN_BOTTOM = require('../assets/toolbar_icons/ic-aligntobottom.svg');
export const IC_ALIGN_LEFT = require('../assets/toolbar_icons/ic-aligntoleft.svg');
export const IC_ALIGN_BETWEENV = require('../assets/toolbar_icons/ic-align-betweenelemetsv.svg');
export const IC_ALIGN_BETWEENH = require('../assets/toolbar_icons/ic-align-betweenelemetsh.svg');

/** Object management */
export const IC_DUPLICATE = require('../assets/toolbar_icons/ic-duplicate.svg');
export const IC_DELETE = require('../assets/toolbar_icons/ic-delete.svg');

/** Background type */
export const BGICON1 = require('../assets/background_icons/bg_icon1.png');
export const BGICON2 = require('../assets/background_icons/bg_icon2.png');
export const BGICON3 = require('../assets/background_icons/bg_icon3.png');
export const BGICON4 = require('../assets/background_icons/bg_icon4.png');
