import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button } from '@material-ui/core';

const styles = {
  button: {
    display: 'inline-flex',
    width: 34,
    height: 34,
    minWidth: 34,
    margin: 0,
    padding: 0,
    borderRadius: 4,
    color: '#1c1c1c',
    fontSize: 15,
    textAlign: 'center',
    transition: 'all 0.2s',
  },
  image: {
    width: 34,
    height: 34,
  },
  active: {
    color: 'white',
    backgroundColor: 'rgb(64, 137, 240)',
  },
}

function ToolButton(props) {
  const { classes, isActive, activeImage, inActiveImage, onClick, ...rest } = props;

  return (
    <Button
      className={classNames(classes.button, {
        [classes.active]: isActive,
      })}
      onClick={onClick}
      {...rest}
    >
      <img src={isActive ? activeImage : inActiveImage} alt="T" />
    </Button>
  )
}

ToolButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  activeImage: PropTypes.any.isRequired,
  inActiveImage: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
ToolButton.defaultProps = {
  isActive: false,
}

export default withStyles(styles)(ToolButton);
