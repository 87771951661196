import React, { useState } from 'react';
import { ButtonBase, withStyles, Popover, Tooltip } from "@material-ui/core";
import { colors } from '../../constants/calculation';

const styles = {
  colorPicker: {
    minWidth: 34,
    width: 34,
    height: 34,
    margin: '0 10px',
    padding: 0,
    border: '1px solid #707070',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
  lightTooltip: {
    backgroundColor: 'white',
    color: '#1c1c1c',
    fontSize: 11,
  },
  wrapper: {
    width: 230,
    height: 98,
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  color: {
    width: 34,
    height: 34,
    margin: 5,
    padding: 0,
    border: '1px solid rgba(0,0,0,0.4)',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
}


function ColorPicker(props) {
  const { classes, color, onColorChange, onMouseOver, onMouseLeave } = props;
  const [anchor, setAnchor] = useState(null);

  function handleOpenColorPicker(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function handleColorChange(newColor) {
    onColorChange(color.index, newColor);
  }

  function handleMouseEnter() {
    if (onMouseOver) {
      onMouseOver(color.index);
    }
  }

  function handleMouseLeave() {
    if (onMouseLeave) {
      onMouseLeave(color.index);
    }
  }

  function Color(color) {
    function handleColorPick() {
      handleColorChange(color.color);
    }

    return (
      <Tooltip key={color.color} title={color.title} classes={{ tooltip: classes.lightTooltip }}>
        <ButtonBase
          className={classes.color}
          style={{ backgroundColor: color.color }}
          onClick={handleColorPick}
        />
      </Tooltip>
    )
  }

  return (
    <>
      <ButtonBase
        className={classes.colorPicker}
        style={{ backgroundColor: color.fill }}
        onClick={handleOpenColorPicker}
        onMouseEnter={handleMouseEnter}
        onMouseOut={handleMouseLeave}
      />
      <Popover
        id="align-objects-popover"
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.wrapper}>
          {colors.map(color => Color(color))}
        </div>
      </Popover>
    </>
  )
}

export default withStyles(styles)(ColorPicker);
