import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Drawer from '../drawer';
import Header from '../header';
import Toolbar from '../toolbar';

function useDrawerStatus(initialValue = true) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(initialValue);

  function handleDrawerOpen() {
    setIsDrawerOpen(true);
  }

  function handleDrawerClose() {
    setIsDrawerOpen(false);
  }

  function handleDrawerToggle() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  return {
    isDrawerOpen,
    openDrawer: handleDrawerOpen,
    closeDrawer: handleDrawerClose,
    toggleDrawer: handleDrawerToggle,
  };
}

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 64,
  },
});

function Layout(props) {
  const { classes, children } = props;
  const drawer = useDrawerStatus();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header {...drawer} />
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
      <Drawer {...drawer} />
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Layout);
