import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import IMG_LOGIN_LOGO from '../../../assets/login-logo.png';
import { actionTypes } from '../../../reducer';

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 800,
    height: 570,
    color: 'white',
    borderRadius: 2,
    backgroundColor: 'black',
    boxShadow: '0 0 8px 0 #000',
  },
  inputLabel: {
    marginTop: 72,
    marginBottom: 15,
  },
  input: {
    width: 400,
    height: 46,
    padding: 10,
    fontSize: 21,
    boxSizing: 'border-box',
    border: '1px solid #707070',
    lineHeight: '26px',
  },
  button: {
    width: 140,
    height: 46,
    marginTop: 10,
    border: '1px solid white',
    backgroundColor: 'rgba(240, 13, 82, 1)',
    color: 'white',
    fontSize: 24,

    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(240, 13, 82, 0.8)',
    },
  }
};

function LoginScreen(props) {
  const { classes } = props;
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  function handleChange(event) {
    setPassword(event.target.value);
  }

  function handleNext() {
    if(password === 'AmpedCustom!') {
      props.updateAuth(true);
    }
    else {
      setError(true);
    }
  }

  function handleClose() {
    setError(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <img src={IMG_LOGIN_LOGO} alt="AMPED & CO" />
        <Typography
          variant="h4"
          color="inherit"
        >
          Custom Neon Platform
        </Typography>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.inputLabel}
        >
          Please enter the password to continue
        </Typography>
        <input
          type="password"
          value={password}
          className={classes.input} placeholder="PASSWORD"
          onChange={handleChange}
        />
        <Button
          className={classes.button}
          onClick={handleNext}
        >
          ENTER
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={error}
          onClose={handleClose}
          message={
            <span>
              {password === '' ? (
                'Password is missing. Please enter your password.'
              ) : (
                'Your password was entered incorrectly. Please try again.'
              )}
            </span>
          }
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateAuth: (value) => ({
    type: actionTypes.UPDATE_AUTH,
    value
  })
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginScreen));
