import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { actionTypes } from '../../reducer';
import { bgIconsList } from '../../constants/calculation';
import { calc } from '../../utils/export';

const styles = theme => ({
  root: {
    padding: '64px 0 0 0',
    height: '100%',
  },
  tabContents: {
    height: '100%',
    overflowY: 'scroll',
  },
  sectionTitle: {
    fontSize: 25,
    fontWeight: 900,
    textTransform: 'uppercase',
    marginTop: 5,
    padding: '0px 20px',
  },
  bgImage: {
    height: 70,
  },
  bgItem: {
    textTransform: 'uppercase',
    fontSize: 20,
  },
  formControl: {
    margin: `0 0 0 ${theme.spacing.unit * 3}px`,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

function TabConfigure(props) {
  const { classes, state } = props;

  function handleChangeBgType(event, index) {
    props.updateBgType(index);
    calc();
  };

  function handleChangepowerCord(event) {
    props.updatePowerCord(event);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabContents}>
        <Typography className={classes.sectionTitle}>Background</Typography>
        <List component="nav">
          {bgIconsList.map((bgIcon, index) => (
            <ListItem
              button
              selected={state.bgType === index}
              onClick={event => handleChangeBgType(event, index)}
            >
              <img className={classes.bgImage} src={bgIcon.icon} alt={bgIcon.title} />
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    className={classes.bgItem}
                  >
                    {bgIcon.title}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <Typography className={classes.sectionTitle}>Power Cord</Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="powerCordType"
            name="powerCordType"
            value={state.powerCord}
            className={classes.group}
            onChange={handleChangepowerCord}
            row
          >
            <FormControlLabel value="White" control={<Radio color="grey" />} label="WHITE" />
            <FormControlLabel value="Black" control={<Radio color="grey" />} label="BLACK" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  )
}


const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateBgType: (index) => ({
    type: actionTypes.UPDATE_BG_TYPE,
    bgType: index,
  }),
  updatePowerCord: (event) => ({
    type: actionTypes.UPDATE_POWER_CODE,
    powerCordType: event.target.value,
  })
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TabConfigure));
