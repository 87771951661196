import { BGICON1, BGICON2, BGICON3, BGICON4 } from "./images";


export const FONT_INFO = {
  'Sanchez': { 'font': 'Sanchez', 'fontsize': 346 / 7.0866, 'spacing': 1.1, 'variance': 1.45 },
  'Allura': { 'font': 'Allura', 'fontsize': 532 / 7.0866, 'spacing': 0, 'variance': 1.40 },
  'Nunito': { 'font': 'Nunito', 'fontsize': 345 / 7.0866, 'spacing': 1.8, 'variance': 1.50 },
  'AmaticSC': { 'font': 'AmaticSC', 'fontsize': 500 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Caveat': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Charmonman': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Comfortaa': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'CutiveMono': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'GiveYouGlory': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Gruppo': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'JuliusSansOne': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'MrDafoe': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'PoiretOne': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'ReenieBeanie': { 'font': 'AmaticSC', 'fontsize': 500 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'RockSalt': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Sacramento': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'Satisfy': { 'font': 'AmaticSC', 'fontsize': 345 / 7.0866, 'spacing': 1, 'variance': 1.50 },
  'ShadowsIntoLight': { 'font': 'AmaticSC', 'fontsize': 500 / 7.0866, 'spacing': 1, 'variance': 1.50 },
}
export const TUBINGCOLORCOSTS = {
  'white': 27.8,
  'other': 27.8
}
export const SIGNCOSTS = {
  "five": [65.6, 72.1, 294.4, 0],
  "ten": [131.1, 144.2, 590.0, 0]
}
export const DRIVECOSTS = {
  "3kv": 12.2,
  "4kv": 16.7,
  "6kv": 18.9,
  "8kv": 24.4,
  "10kv": 25.6
}
export const bgIconsList = [
  {
    icon: BGICON1,
    title: 'Straight edges',
  },
  {
    icon: BGICON2,
    title: 'Contour edges',
  },
  {
    icon: BGICON3,
    title: 'Acrylic box',
  },
  {
    icon: BGICON4,
    title: 'None',
  },
];
export const shippingBoxCostUnit = 138.8;
export const rateCmToPx = 37.79527559055118;
export const rateCanvasToReal = rateCmToPx / 4;
export const FULLSCREEN_RATE = 700 / 480;
export const SCREEN_SCALE = window.devicePixelRatio || 1;
export const PGTForIndividual = 5;
export const PGTForMaster = 7;
export const individualPackagingT = 12;
export const tarrifRate = 0.07;
export const backgroundStyle = 'Clear';
export const colors = [
  { color: '#ffffff', title: 'White' },
  { color: '#fffca1', title: 'Light Yellow' },
  { color: '#ffcd1a', title: 'Orange (painted tube)' },
  { color: '#9bff82', title: 'Light Green' },
  { color: '#43e102', title: 'Dark Green (painted tube)' },
  { color: '#3bdaff', title: 'Light Blue' },
  { color: '#2083ff', title: 'Dark Blue (painted tube)' },
  { color: '#ff9fcb', title: 'Pink' },
  { color: '#d28aff', title: 'Purple' },
  { color: '#ff2e2e', title: 'Red (painted tube)' },
];

export const colorNames = {
  '#ffffff': 'White',
  '#fffca1': 'Light Yellow',
  '#ffcd1a': 'Orange',
  '#9bff82': 'Light Green',
  '#43e102': 'Dark Green',
  '#3bdaff': 'Light Blue',
  '#2083ff': 'Dark Blue',
  '#ff9fcb': 'Pink',
  '#d28aff': 'Purple',
  '#ff2e2e': 'Red',
}

