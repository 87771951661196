import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton, withStyles, Popover, Button } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ImageIcon from '@material-ui/icons/Image';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import IMG_BG_1 from '../../assets/background/background-1_tn.jpg';
import IMG_BG_2 from '../../assets/background/background-2_tn.jpg';
import IMG_BG_3 from '../../assets/background/background-3_tn.jpg';

import { actionTypes } from '../../reducer';
import { toggleFullScreen } from '../../utils/fabric';
import { exportCanvasToPDF } from '../../utils/export';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: '100%',
    width: 80,
  },
  sizeContainer: {
    margin: '0 10px',
    padding: '5px 7px',
    color: '#1c1c1c',
    border: '1px solid #707070',
    borderRadius: 4,
  },
  image: {
    objectFit: 'cover',
  },
}

function InfoBar(props) {
  const { classes, state } = props;
  const [anchor, setAnchor] = useState(null);

  function handleOpenAlignments(event) {
    setAnchor(event.currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function handleBackgroundChange(type) {
    return function () {
      setAnchor(null);
      props.updateBgImage(type);
    }
  }

  function handleToggleFullScreen() {
    toggleFullScreen();
    props.toggleFullScreen();
  }

  function handleDownload() {
    exportCanvasToPDF();
  }

  return (
    <div className={classes.wrapper}>
      <IconButton onClick={handleToggleFullScreen}>
        {state.isFullScreen ? <ZoomOutIcon fontSize="large" /> : <ZoomInIcon fontSize="large" />}
      </IconButton>
      <IconButton
        aria-owns={!!anchor ? 'select-background-popover' : undefined}
        aria-haspopup
        onClick={handleOpenAlignments}
      >
        <ImageIcon fontSize="large" />
      </IconButton>
      <Popover
        id="select-background-popover"
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button onClick={handleBackgroundChange(0)}>
          <img className={classes.image} src={IMG_BG_1} alt="bg_1" />
        </Button>
        <Button onClick={handleBackgroundChange(1)}>
          <img className={classes.image} src={IMG_BG_2} alt="bg_2" />
        </Button>
        <Button onClick={handleBackgroundChange(2)}>
          <img className={classes.image} src={IMG_BG_3} alt="bg_3" />
        </Button>
      </Popover>
      <IconButton onClick={handleDownload}>
        <DownloadIcon fontSize="large" />
      </IconButton>
    </div>
  )
}


const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateBgImage: (type) => ({
    type: actionTypes.UPDATE_BG_IMAGE,
    value: type
  }),
  toggleFullScreen: (property, value) => ({
    type: actionTypes.TOGGLE_FULLSCREEN
  })
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoBar));
