import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import FontPicker from './FontPicker';
import TextColorPicker from './TextColorPicker';
import TextAlignBar from './TextAlignBar';
import AlignItemsBar from './AlignItemsBar';
import ManageBar from './ManageBar';
import ShapeColorPicker from './ShapeColorPicker';
import GroupColorPicker from './GroupColorPicker';

const styles = theme => ({
  toolBar: {
    position: 'absolute',
    justifyContent: 'center',
    background: '#ebebeb',
    boxShadow: '0 0 8px 1px #707070',
    transition: 'top 0.3s ease',
    left: 0,
    top: 0,
    right: 0,
    height: 64,
    zIndex: theme.zIndex.appBar - 1,
  },
  active: {
    top: 64,
  },
  length: {
    height: 34,
    margin: '0 10px',
    padding: '0 15px',
    border: '1px solid #707070',
    borderRadius: 4,
    color: '#707070',
    lineHeight: '34px',
  },
});

function Header(props) {
  const { classes, state } = props;

  function _renderToolbar() {
    if (!state.activeObject) {
      return null;
    }
    switch (state.activeObject.type) {
      case 'i-text':
        return (
          <>
            <FontPicker />
            <div className={classes.length}>{state.length.toFixed(2)} cm</div>
            <TextColorPicker />
            <TextAlignBar />
          </>
        );
      case 'activeSelection':
        return (
          <>
            <GroupColorPicker />
            <AlignItemsBar />
          </>
        );
      default:
        return <ShapeColorPicker />
    }
  }

  return (
    <Toolbar className={classNames(classes.toolBar, {
      [classes.active]: !!state.activeObject
    })}>
      {_renderToolbar()}
      <ManageBar />
    </Toolbar >
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ state });

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(Header));
