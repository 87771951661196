import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';

import { openedDrawerWidth } from '../theme';
import TextTab from './tab_design/TextTab';
import IconsTab from './tab_design/IconsTab';
import PremadeTab from './tab_design/PremadeTab';

const styles = theme => ({
  fullHeihgt: {
    height: '100%',
    paddingTop: 108,
  },
  tabsRoot: {
    position: 'fixed',
    background: 'white',
    zIndex: 9,
    top: 128,
    borderBottom: 'none',
    boxShadow: '0 7px 11px -10px #707070',
    minHeight: 40,
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    textTransform: 'uppercase',
    minWidth: openedDrawerWidth / 3,
    minHeight: 40,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#3b86ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#3b86ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#3b86ff',
    },
  },
  tabSelected: {},
  tabContents: {
    height: '100%',
    overflowY: 'scroll',
  },
  textItem: {
    textAlign: 'center',
    fontSize: 25,
    fontFamily: 'Allura'
  }
});

function TabDesgin(props) {
  const { classes } = props;
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, value) => {
    setTabIndex(value);
  }

  return (
    <div className={classes.fullHeihgt}>
      <Tabs 
        value={tabIndex}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <Tab
          label='Text'
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label='Icons'
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab 
          label='Premade'
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>
      <div className={classes.tabContents}>
        {tabIndex === 0 && <TextTab />}
        {tabIndex === 1 && <IconsTab />}
        {tabIndex === 2 && <PremadeTab />}
      </div>
    </div>
  )
}

export default withStyles(styles)(TabDesgin)
