import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import REVIEWICON from '../../assets/process_icon/review.svg';
import { bgIconsList } from '../../constants/calculation';

const styles = theme => ({
  root: {
    padding: '20px',
    fontSize: 30,
  },
  subject: {
    fontSize: 25,
    fontWeight: 900,
    textTransform: 'uppercase',
    marginTop: 5,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  subjectIcon: {
    marginRight: 20,
    width: 40,
    height: 42,
  },
  itemRow: {
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelText: {
    width: '48%',
    textAlign: 'right',
    paddingRight: '7%',
  },
  valText: {
    width: '45%',
    fontWeight: 900,
    textAlign: 'left',
  },
});

function ProcessReview(props) {
  const { classes, state } = props;

  return (
    <div className={classes.root}>
      <div className={classes.subject}><img src={REVIEWICON}  className={classes.subjectIcon} alt='Review' /> Preview</div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Size:</div> <div className={classes.valText}>{`${state.width + 6}cm x ${state.height + 6}cm`}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Tube color:</div> <div className={classes.valText}>{state.colorList.join(', ')}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Mounting:</div> <div className={classes.valText}>{bgIconsList[state.bgType].title}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Power Cord:</div> <div className={classes.valText}>{state.powerCord}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(ProcessReview));
