import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CONFIRMICON from '../../assets/process_icon/confirm.svg';
import { actionTypes } from '../../reducer';

const styles = theme => ({
  root: {
    padding: '20px',
    fontSize: 30,
    height: '100%',
    overflowY: 'scroll',
  },
  subject: {
    fontSize: 25,
    fontWeight: 900,
    textTransform: 'uppercase',
    marginTop: 5,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  subjectIcon: {
    marginRight: 20,
    width: 40,
    height: 42,
  },
  itemRow: {
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  labelText: {
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  valText: {
    marginLeft: 10,
  },
});

function ProcessCompleteOrder(props) {
  const { classes, state } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.subject}><img src={CONFIRMICON} className={classes.subjectIcon} alt='Review' /> Confirm</Typography>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Company:</div>
        <div className={classes.valText}>{state.form.company}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Name:</div>
        <div className={classes.valText}>{state.form.name}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Email:</div>
        <div className={classes.valText}>{state.form.email}</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Phone:</div>
        <div className={classes.valText}>{state.form.phone}</div>
      </div>
      <br />
      <div className={classes.itemRow}>
        <div className={classes.labelText}>Shipping Address:</div>
      </div>
      <div className={classes.itemRow}>
        <div className={classes.valText}>{state.form.shippingAddress1}</div>
      </div>
      {state.form.shippingAddress2 &&
        <div className={classes.itemRow}>
          <div className={classes.valText}>{state.form.shippingAddress2}</div>
        </div>
      }
      <div className={classes.itemRow}>
        <div className={classes.valText}>{state.form.city}, {state.form.state}, {state.form.zip}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: (property, value) => ({
    type: actionTypes.UPDATE_FORM,
    property,
    value,
  }),
}, dispatch);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProcessCompleteOrder));
