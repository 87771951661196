import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { addNewObject } from '../../../utils/fabric';
import { fontsList } from '../../../constants/fonts';
import { FONT_INFO } from '../../../constants/calculation';

const styles = {
  fontItem: {
    textAlign: 'center',
    fontSize: 45,
  }
};

function TextTab(props) {
  const { classes, state } = props;

  const handleListItemClick = async (event, index) => {
    addNewObject({
      type: 'i-text',
      data: {
        context: fontsList[index].name,
        property: {
          fill: '#FFFFFF',
          fontSize: FONT_INFO[fontsList[index].file].fontsize,
          fontWeight: 'normal',
          fontFamily: fontsList[index].file,
          fontStyle: 'normal',
          lineHeight: 1.1,
          charSpacing: FONT_INFO[fontsList[index].file].spacing * 7.0866,
          textShadow: '',
          textAlign: 'center',
        },
      },
    });
  };

  return (
    <List component="nav">
      {fontsList.map((font, index) => (
        <ListItem
          key={index}
          button
          onClick={event => handleListItemClick(event, index)}
        >
          <ListItemText
            primary={
              <Typography
                component="span"
                className={classes.fontItem}
                style={{ fontFamily: `${font.file}` }}
              >
                {font.name}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(TextTab));
