import React from 'react';
import Layout from '../../../components/layout/Layout';
import Editor from '../../../components/editor';

export default function () {
  return (
    <Layout>
      <Editor />
    </Layout>
  )
}
