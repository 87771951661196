import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { addNewObject } from '../../../utils/fabric';

const styles = {
  root: {
    padding: '20px',
  },
  iconContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.14)'
    },
  },
  widePadding: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  gridListTile: {
    height: 'auto !important',
    padding: '10px !important',
  },
  fontIconImage: {
    width: '100%',
    height: 'auto',
  },
};

function Icon({ classes, col, onAddShape, iconIndex }) {
  const icon = require(`../../../assets/font_icons/font_icon${iconIndex + 1}.svg`);

  function handleAddObject() {
    onAddShape(icon, iconIndex);
  }

  return (
    <div
      className={classNames(classes.iconContainer, {
        [classes.widePadding]: col === 2,
      })}
      onClick={handleAddObject}
    >
      <img className={classes.fontIconImage} src={icon} alt='Font icon' />
    </div>
  );
}

function IconsTab(props) {
  const { classes, state } = props;
  const tileData = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1];

  function handleAddShape(icon, iconIndex) {
    addNewObject({
      type: 'shape',
      data: {
        context: icon,
        property: { iconIndex },
      },
    });
  };

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2}>
        {tileData.map((col, index) => (
          <GridListTile
            key={`tile-${index}`}
            cols={col || 1}
            className={classes.gridListTile}
          >
            <Icon
              classes={classes}
              col={col}
              onAddShape={handleAddShape}
              iconIndex={index}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

const mapStateToProps = state => ({ state });

export default withStyles(styles)(connect(mapStateToProps, null)(IconsTab));
